import { useContext } from 'react'
import { StoreContext } from '../../contexts'
import { useGetLineItem, useRemoveItemFromCart, useConvertCustomAttributes, useAreCustomAttributesIdentical, useSortCustomAttributes } from '../index'


export function useUpdateItem() {
  const { client, cart, setCart } = useContext(StoreContext)
  const getLineItem = useGetLineItem()
  const removeItemFromCart = useRemoveItemFromCart()
  const convertCustomAttributes = useConvertCustomAttributes()
  const sortCustomAttributes = useSortCustomAttributes()
  const areCustomAttributesIdentical = useAreCustomAttributesIdentical()

  async function updateItem(
    variantId: string | number,
    newQuantity: number,
    currentCustomAttributes: Array<any>,
    newCustomAttributes?: Array<any>,
  ) {
    if (variantId == null) {
      throw new Error('Must provide a variant id')
    }

    if (currentCustomAttributes == null) {
      throw new Error('You must provide currentCustomAttributes')
    }

    const currentLineItem = getLineItem(variantId, currentCustomAttributes)
    
    if (currentLineItem == null) {
      throw new Error(`Item with variantId ${variantId} not in cart`)
    }

    if (newCustomAttributes) {
      const sortedConvertedCurrentAttributes = sortCustomAttributes(convertCustomAttributes(currentCustomAttributes))
      const sortedConvertedNewAttributes = sortCustomAttributes(convertCustomAttributes(newCustomAttributes))

      if (areCustomAttributesIdentical(sortedConvertedCurrentAttributes, sortedConvertedNewAttributes)) {
        throw new Error('currentCustomAttributes and newCustomAttributes cannot be the same')
      }

      // Check if lineItem with same variantID and newCustomAttributes already exists
      const targetLineItemIndex = cart.lineItems.findIndex(item => item.variant.id === variantId && areCustomAttributesIdentical(sortCustomAttributes(convertCustomAttributes(item.customAttributes)), sortedConvertedNewAttributes))
      if (targetLineItemIndex !== -1) {
        const targetLineItem = cart.lineItems[targetLineItemIndex]
        // Store new total quantity
        const newTotalQuantity = targetLineItem.quantity + newQuantity
        // update target line item quantity
        const newCart = await client.checkout.updateLineItems(cart.id, [{ id: targetLineItem.id, quantity: newTotalQuantity },])
        setCart(newCart)
        // remove original line item
        await removeItemFromCart(currentLineItem.id)
      } else {
        // Update currentLine item's customAttributes
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        const newCart = await client.checkout.updateLineItems(cart.id, [{ id: currentLineItem.id, quantity: newQuantity, customAttributes: sortedConvertedNewAttributes },])
        setCart(newCart)
      }
    } else {
      // Just update the quantity
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      const newCart = await client.checkout.updateLineItems(cart.id, [{ id: currentLineItem.id, quantity: newQuantity },])
      setCart(newCart)
    }
  }

  return updateItem
}
