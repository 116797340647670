import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import { desktopBreakpoint, colors, desktopVW, mobileVW } from '../../../styles/index';
import Img from 'gatsby-image';
import { SettingsContext } from '../../../contexts';
import { useSpring, animated, config } from 'react-spring';
import DelayedLink from '../../../utils/DelayedLink';
import paper from '../../../../static/paperBackground/paper.jpeg';
import { useInView } from 'react-intersection-observer';
import { useLocale } from '../../../hooks/layout/useLocale';
import { useGlobalDictionaryQuery } from '../../../hooks/index';
import { navigate } from 'gatsby-link';

interface Props {
  data: any;
  closeBothMenus: any;
  handleSubMenuClick: any;
  items: any;
}

export default function SecondMenuDesktop(props: Props) {
  const { data, closeBothMenus, handleSubMenuClick, items } = props;

  const {
    secondMenuContent,
    productNews,
  }: {
    secondMenuContent: any;
    productNews: any;
  } = useContext(SettingsContext);

  const { products, targetData } = data;

  const dictionary = useGlobalDictionaryQuery();

  const [trigger, triggerInView] = useInView({
    threshold: 0,
  });

  const productsPerLoad = 12;
  const [limit, setLimit] = useState(productsPerLoad);
  const [title, setTitle] = useState('');

  const [wrapperAnimation, setWrapperAnimation] = useSpring(() => ({
    transform: 'translateX(0%) ',
    transformOrigin: 'center top',
    paddingTop: desktopVW(140),
    opacity: 1,
    config: {
      tension: 180,
      friction: 25,
    },
  }));

  const delayedCallback = () => {
    closeBothMenus();
  };

  useEffect(() => {
    setWrapperAnimation({ transform: 'translateX(-30%) ', opacity: 0 });
    let callback = setTimeout(() => {
      if (typeof secondMenuContent === 'string') {
        secondMenuContent.toLowerCase().trim() === 'all products' ||
        secondMenuContent.toLowerCase().trim() === 'alle producten'
          ? setTitle(`${dictionary.discoverAllProducts}`)
          : setTitle(`${dictionary.discoverOur} ${secondMenuContent.toLowerCase()}`);
        setWrapperAnimation({ transform: 'translateX(0%) ', opacity: 1 });
      }
    }, 400);
    return () => clearTimeout(callback);
  }, [secondMenuContent]);

  useEffect(() => {
    if (triggerInView) setLimit(limit + productsPerLoad);
  }, [triggerInView]);

  const locale = useLocale();

  const handleSeeMoreClick = () => {
    const targetLink = items.menus && items.menus[0].target.slug;
    navigate(`/${locale}/${targetLink}/`);
    handleSubMenuClick(targetData);
  };

  return (
    <Wrapper>
      <animated.div style={{ ...wrapperAnimation }}>
        <SecondMenuHeader>{title}</SecondMenuHeader>
        <GridWrapper>
          {title?.includes('new') || title?.includes('nieuw') ? (
            <>
              {productNews
                ? productNews.map(({ titleToShow, slug, thumbnailImages }, index) => {
                    if (index < limit) {
                      return (
                        <DelayedLink
                          to={`/${locale}/${slug}/`}
                          key={`${titleToShow}${index}`}
                          location={location}
                          callback={delayedCallback}
                          delay={1000}
                        >
                          <ThumbnailWrapper>
                            <StyledImg fluid={thumbnailImages[1].fluid} />
                            <ProductTitle>{titleToShow}</ProductTitle>
                          </ThumbnailWrapper>
                        </DelayedLink>
                      );
                    }
                  })
                : false}
            </>
          ) : (
            <>
              {products
                ? products.map(({ titleToShow, slug, thumbnailImages }, index) => {
                    if (index < limit) {
                      return (
                        <DelayedLink
                          to={`/${locale}/${slug}/`}
                          key={`${titleToShow}${index}`}
                          location={location}
                          callback={delayedCallback}
                          delay={1000}
                        >
                          <ThumbnailWrapper>
                            <StyledImg fluid={thumbnailImages[1].fluid} />
                            <ProductTitle>{titleToShow}</ProductTitle>
                          </ThumbnailWrapper>
                        </DelayedLink>
                      );
                    }
                  })
                : false}
            </>
          )}

          <SeeMoreWrapper onClick={handleSeeMoreClick}>
            <SeeMoreSpan>{dictionary.seeMoreProducts}</SeeMoreSpan>
          </SeeMoreWrapper>
          <div ref={trigger}></div>
        </GridWrapper>
      </animated.div>
    </Wrapper>
  );
}

const Wrapper = styled(animated.div)`
  background: url(${paper});
  padding: 0 ${desktopVW(30)};
  width: 100%;
  min-height: 100%;
`;

const GridWrapper = styled.div`
  width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: ${desktopVW(30)};
  grid-column-gap: ${desktopVW(40)};
  will-change: 'transform';
`;

const SeeMoreWrapper = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${colors.fakeProductBackground};
  height: ${desktopVW(250)};
  margin-bottom: ${desktopVW(20)};
`;

const SeeMoreSpan = styled.span`
  color: black;
  font-family: 'messinaMono';
`;

const ThumbnailWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;

  ${desktopBreakpoint} {
  }
`;

const StyledImg = styled(Img)`
  height: ${desktopVW(250)};
  width: 100%;
`;

const ProductTitle = styled.p`
  font-family: 'messinaMono';
  letter-spacing: -0.05em;
  color: ${colors.black};
  font-size: ${desktopVW(14)};
  padding: ${desktopVW(10)} 0 0 0;

  ${desktopBreakpoint} {
  }
`;

const SecondMenuHeader = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: ${desktopVW(120)};
  display: flex;
  align-items: center;
  font-family: 'messinaMono';
  font-weight: 400;
  letter-spacing: -0.05em;
  color: ${colors.black};
  font-size: ${desktopVW(30)};
`;

const FooterSecondMenu = styled.div`
  /* position: absolute;
  bottom: 0;
  left: 0; */
  color: ${colors.black};
  font-size: ${desktopVW(20)};
  font-family: 'messinaMono';
  font-weight: 400;
  letter-spacing: -0.05em;
  color: ${colors.black};
  font-size: ${desktopVW(25)};
  text-decoration: underline;
  padding-top: ${desktopVW(40)};

  ${desktopBreakpoint} {
  }
`;
