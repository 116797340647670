import React from 'react';
import { Link } from 'gatsby';
import { useLocale } from '../../hooks/layout/useLocale';

const LinkTool = ({ to, children }) => {
  let locale = useLocale();
  if (typeof locale == 'undefined') locale = location.href.includes('/nl') ? 'nl' : 'en';
  return <Link to={`${locale ? `/${locale}` : ''}${to}`}>{children}</Link>;
};

export default LinkTool;
