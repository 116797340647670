import React from 'react'
import styled from 'styled-components'
import { desktopBreakpoint, colors, desktopVW, mobileVW } from '../../../styles/index'
import {useGlobalSettingsQuery, useGlobalDictionaryQuery} from '../../../hooks/index'



interface Props {
  
}

export default function FooterSocialBar(props: Props) {

  const {
    
  } = props

  const {
    instagramPageLink,
    instagramFooterHashLink,
    pinterestPageLink
  }: {
    instagramPageLink: string
    instagramFooterHashLink: string
    pinterestPageLink: string
  } = useGlobalSettingsQuery()

  const {
    hashLinkFooter
  }: {
    hashLinkFooter: string
  } = useGlobalDictionaryQuery()

  return (
    <Wrapper>
      <a
        href={instagramPageLink}
        target='_blank'
        rel='noopener'
        aria-label='Facebook'
      >
        Instagram
      </a>
      <a
        href={instagramFooterHashLink}
        target='_blank'
        rel='noopener'
        aria-label='Facebook'
      >
        {hashLinkFooter}
      </a>
      <a
        href={pinterestPageLink}
        target='_blank'
        rel='noopener'
        aria-label='Facebook'
      >
        Pinterest
      </a>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background-color: ${colors.frappuccino};
  width: 100vw;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: ${mobileVW(30)} ${mobileVW(17)};

  a{
    cursor: pointer;
    font-family: 'messinaMono';
    letter-spacing: -0.05em;
    color: ${colors.black};
    font-size: ${mobileVW(16)};

    ${desktopBreakpoint}{
      font-size: ${desktopVW(25)};
    }
  }

  ${desktopBreakpoint}{
    padding: ${desktopVW(50)} ${desktopVW(60)};
  }
`;