import React from 'react'
import styled from 'styled-components'
import { desktopBreakpoint, colors, desktopVW, mobileVW, mobileContentSidePadding, zIndex } from '../../../styles/index'
import Swiper from 'react-id-swiper'
import 'swiper/css/swiper.css'
import { useGlobalLayoutQuery } from '../../../hooks/index'
import Img from 'gatsby-image'




interface Props {
  
}

export default function FooterLookbook(props: Props) {

  const {
    
  } = props

  const layout = useGlobalLayoutQuery()

  const params = {
    slidesPerView: 'auto',
    spaceBetween: 15,
    grabCursor: true,
    breakpoints: {
      1023: {
        spaceBetween: 30,
      },
    },
  }

  return (
    <Wrapper>
      <SwiperWrapper className="product-slider-wrapper">
        <Swiper {...params}>
          {layout?.lookbookFooter.map((itm, index) => (
            <div className="swiper-slide" key={index}>
              <a
                href={itm.description}
                target="_blank"
                rel="noopener"
                aria-label={itm.title}
              >
                <ImageWrapper>
                  <StyledImg fluid={itm.fluid} />
                </ImageWrapper>
              </a>
            </div>
          ))}
        </Swiper>
      </SwiperWrapper>
    </Wrapper>
  );
}



const Wrapper = styled.div`
  background-color: ${colors.frappuccino};
  width: 100%;
  overflow: hidden;
  padding: ${mobileVW(60)} 0 0 0;
  
  ${desktopBreakpoint}{
    padding: ${desktopVW(78)} 0 0 0;
  }
`;

const SwiperWrapper = styled.div`
  position: relative;
  width: 100%;

  .swiper-container {
    width: 90vw;
    overflow: visible;
    margin: 0 ${desktopVW(0)} 0 ${desktopVW(60)};
    
  }
  .swiper-wrapper {
    margin: 0;
    padding: 0;
  }

  .swiper-slide{
    height: ${mobileVW(320)};
    width: ${mobileVW(320)};

    ${desktopBreakpoint}{
      height: ${desktopVW(410)};
      width: ${desktopVW(410)};
    }
    
  }

`;

const ImageWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: ${mobileVW(320)};
  height: ${mobileVW(320)};
  z-index: ${zIndex.backgrounds};

  ${desktopBreakpoint}{
    height: ${desktopVW(410)};
    width: ${desktopVW(410)};
  }
`;

const StyledImg = styled(Img)`
  width: 100%;
`;