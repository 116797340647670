import React, { useState } from 'react';
import styled from 'styled-components';
import { subscribe } from 'klaviyo-subscribe';
import { animated, useSpring, config } from 'react-spring';
import Cookies from 'js-cookie';
import {
  desktopBreakpoint,
  colors,
  desktopVW,
  mobileVW,
  zIndex,
  SpacerDesktop,
  SpacerMobile,
  SpringConfig,
} from '../../styles/index';
import RichText from '../shared/RichText';
import { useGlobalDictionaryQuery, useGlobalComponentsQuery } from '../../hooks/index';
import Input from '../shared/Input';
import Button from '../shared/Button';

interface Props {}

export default function SubscribeForm(props: Props) {
  const {} = props;

  const dictionary = useGlobalDictionaryQuery();

  const global = useGlobalComponentsQuery();

  const [email, setEmail] = useState('');
  const [containErrors, SetContainErrors] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [text, setText] = useState('');

  const handleContainerrors = state => {
    if (state) {
      SetContainErrors(true);
    }
    if (!state) {
      SetContainErrors(false);
    }
  };

  const reset = () => {
    setClicked(false);
    setLoading(false);
    setEmail('');
  };

  const submitEmail = async e => {
    e.preventDefault();
    setLoading(true);
    setTimeout(() => {
      subscribe(global?.klaviyoNewsletterListId, email).then(response => {
        // console.log(response);
        setClicked(true);
        if (response.success) {
          if (response.data.is_subscribed) {
            setText(dictionary?.emailSubscribeAlreadyInListMsg);
          } else {
            setText(dictionary?.emailSubscribeSuccessMsg);
          }
          setTimeout(() => reset(), 3000);
        } else {
          setText(dictionary?.emailSubscribeFailMsg);
          setTimeout(() => reset(), 3000);
        }
      });
    }, 500);
  };

  const buttonSpring = useSpring({
    transform: clicked ? 'translateY(120%)' : 'translateY(0%)',
    config: SpringConfig.microInteraction,
  });

  const responseSpring = useSpring({
    transform: clicked ? 'translateY(0%)' : 'translateY(350%)',
    config: SpringConfig.microInteraction,
  });

  return (
    <Wrapper onSubmit={e => submitEmail(e)}>
      <SpacerDesktop vertical sHeight={25} />
      <SpacerMobile vertical sHeight={35} />
      <Input
        inputValue={email}
        inputSetValue={setEmail}
        inputName="email"
        inputType="text"
        required
        onChangecallback={e => setEmail(e.target.value)}
        placeholder="Email"
        color={dictionary.colorTextInputNewsletterPopup}
        style={{ border: `${desktopVW(1)} solid ${dictionary.colorTextInputNewsletterPopup}` }}
        width={mobileVW(800)}
        widthDesk={desktopVW(300)}
        fontSizeDesk={desktopVW(16)}
        fontSize={mobileVW(20)}
        validation="onlyEmail"
        setContainErrors={handleContainerrors}
        hasSolidBackground={dictionary.colorBackgroundNewsletterPopup}
      />
      <SpacerDesktop vertical sHeight={23} />
      <SpacerMobile vertical sHeight={23} />
      <OverflowHidden>
        <animated.div style={{ ...buttonSpring }}>
          <Button
            text={loading ? dictionary?.loading : dictionary?.send}
            Type="submit"
            AriaLabel="Send"
            width={mobileVW(370)}
            widthDesk={desktopVW(300)}
            color={dictionary.colorTextButtonNewsletterPopup}
            colorHover={dictionary.colorTextButtonNewsletterPopupHover}
            colorBackground={dictionary.colorBackgroundButtonNewsletterPopup}
            colorBackgroundHover={dictionary.colorBackgroundButtonNewsletterPopupHover}
            colorBorder={dictionary.colorBorderButtonNewsletterPopup}
            colorBorderHover={dictionary.colorBorderButtonNewsletterPopupHover}
            // disableIt={containErrors}
          />
        </animated.div>
        <ResponseMsg style={{ ...responseSpring }}>
          <p>{text}</p>
        </ResponseMsg>
      </OverflowHidden>
    </Wrapper>
  );
}

const Wrapper = styled.form`
  display: flex;
  flex-direction: column;
  height: auto;
  width: 100vw;

  z-index: ${zIndex.surface};
  padding: 0;

  p {
    font-family: 'messinaMonoLight';
    font-size: ${mobileVW(12)};
  }

  ${desktopBreakpoint} {
    width: auto;
    padding: ${desktopVW(0)} ${desktopVW(60)} ${desktopVW(0)} ${desktopVW(60)};
    transform: translate(0%, 0%);

    input {
      /* border: ${desktopVW(1)} solid ${colors.black}; */
    }

    &::placeholder {
      /* background-color: tomato; */
    }

    p {
      font-size: ${desktopVW(14)};
    }
  }
`;

const OverflowHidden = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  transition: 0.3s ease-in;
  ${desktopBreakpoint} {
  }

  &:hover {
    transform: scale(1.04);
  }
`;

const ResponseMsg = styled(animated.div)`
  text-align: center;
  transform: translateY(350%);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;

  ${desktopBreakpoint} {
    top: ${desktopVW(20)};
  }
`;
