import { useState, useEffect } from 'react'

export function useIsMobile() {
  const [isMobile, setIsMobile] = useState(
    typeof window != 'undefined' && window.innerWidth <= 1024 ? true : false,
  )
  useEffect(()=> {
    const isClient = typeof window === 'object'
    if (!isClient) return false
    const handleResize = () => {
      if (window.innerWidth <= 1024) {
        setIsMobile(true)
        return
      }
      setIsMobile(false)
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])
  return isMobile
}
