import Cookies from 'js-cookie';
import React, { useContext, useEffect, useState } from 'react';
import { createGlobalStyle } from 'styled-components';
import { SettingsContext } from '../../contexts';
import { useGlobalComponentsQuery, useGlobalSettingsQuery } from '../../hooks/index';
import { colors, desktopBreakpoint, desktopVW, mobileVW, zIndex } from '../../styles/index';
import NewsletterPopup from '../shared/NewsletterPopup';
import { Portal } from '../shared/Portal';
import Cart from './Cart';
import Footer from './Footer';
import Header from './Header';

import { HelpDeskMenu } from './HelpDeskMenu';
import GetAllPproduts from '../allProducts/GetAllPproduts';

const cookieStatus = typeof window !== 'undefined' ? localStorage.getItem('onetrust-accept') : null;

let showCookiePro = 'none';
if (cookieStatus) {
  showCookiePro = 'none';
} else {
  showCookiePro = 'flex';
}

const Layout = ({ shopMenuHeader, children, pageType, allProductsTitleSlug, navbarColor }) => {
  const {
    renderNewsletterPopup,
    setrenderNewsletterPopup,
    purchaseAllowed,
    setPurchaseAllowed,
    setMenuShop,
    setAllProductReview,
  }: {
    renderNewsletterPopup: any;
    setrenderNewsletterPopup: any;
    purchaseAllowed: any;
    setPurchaseAllowed: any;
    setMenuShop: any;
    setAllProductReview: any;
  } = useContext(SettingsContext);

  const {
    countriesAllowedToPurchase,
  }: {
    countriesAllowedToPurchase: [];
  } = useGlobalSettingsQuery();

  const globalComponents = useGlobalComponentsQuery();

  useEffect(() => {
    if (!Cookies.get('_DBNL-closed')) {
      const popupTimeout = setTimeout(() => {
        setrenderNewsletterPopup(true);
      }, 30000);
      return () => clearTimeout(popupTimeout);
    }
  }, []);

  async function getReviews() {
    const requestOptions = {
      method: 'GET',
    };
    const res = await fetch(
      `https://stamped.io/api/widget/reviews?productType&email&isWithPhotos&minRating&take&page&dateFrom&dateTo&sortReviews&tags&storeUrl=d-bodhi-dev.myshopify.com&apiKey=pubkey-9Fir7iT1X7rn86rVW5DkUz8so27ohh`,
      requestOptions
    );
    return res;
  }

  useEffect(() => {
    getReviews()
      .then(res => res.json())
      .then(res => {
        if (res.data.length > 0) {
          setAllProductReview(res.data);
        }
      });
  }, []);

  useEffect(() => {
    if (shopMenuHeader?.length > 0) {
      setMenuShop(shopMenuHeader[0]);
    }
  }, [shopMenuHeader]);

  useEffect(() => {
    if (!purchaseAllowed.checked) {
      fetch(
        `https://api.ipdata.co/?api-key=65aca6c7e3cc4750d90e2470634a3a5d27d236d024b9a6157eaff8e0`
      )
        .then(res => res.json())
        .then(res => {
          if (res.message) {
            setPurchaseAllowed({
              checked: true,
              country: res.country_name,
              allowed: true,
              error: res.message,
            });
            // console.log('no location fetched')
          } else if (countriesAllowedToPurchase.includes(res.country_code)) {
            setPurchaseAllowed({
              checked: true,
              country: res.country_name,
              allowed: true,
              error: false,
            });
          } else {
            setPurchaseAllowed({
              checked: true,
              country: res.country_name,
              allowed: false,
              error: false,
            });
          }
        });
    }
  }, []);

  return (
    <>
      <GlobalStyle />
      <GetAllPproduts />
      {globalComponents?.displayNewsletter && renderNewsletterPopup && pageType !== 'General' && (
        <Portal id="portal" toggled={renderNewsletterPopup}>
          <NewsletterPopup />
        </Portal>
      )}
      <HelpDeskMenu />
      <Header shopMenuHeader={shopMenuHeader} pageType={pageType} navbarColor={navbarColor} />
      <Cart allProductsTitleSlug={allProductsTitleSlug} />
      <main>{children}</main>
      {pageType && pageType !== 'Search' && <Footer />}
    </>
  );
};

const GlobalStyle = createGlobalStyle`

  *:not(input, button) {
    overscroll-behavior: contain; 
    -webkit-touch-callout: none;
    /* -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-tap-highlight-color: transparent; */
  } 

  *{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
  }

  html {
    background-color: ${colors.frappuccino};
  }

  body {
    margin: 0 auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family:  'Europa', sans-serif;
    width: 100%;
    overflow-x: hidden;
    transition: 0.25s;

    #onetrust-consent-sdk{
      display:${showCookiePro};
    }

    .ot-sdk-container {
      width: 100vw !important;
      overflow: hidden !important;
      padding: 0 !important;
      .banner-close-btn-container {
        margin: 0 !important;
      }
      .ot-sdk-container {
        background-color: ${colors.frappuccino};
        font-family: "messinaMono" !important;  
        position: relative !important;
        height: 230px !important;
        z-index: ${zIndex.surface + 1} !important;
        box-shadow: none !important;
        padding: 0 20px !important;
        
        ${desktopBreakpoint}{
          height: 135px !important;
          width: 800px !important;
          padding: 0 !important;
        }
      }
    }

    #onetrust-banner-sdk{
      box-shadow: none !important;
      width: 100vw !important;
      background-color: ${colors.frappuccino} !important;
      position: fixed !important;
      bottom: 0 !important;
      left: 0 !important;
      z-index: ${zIndex.satan} !important;
      max-width: none !important;

      @media (min-width: 550px){
        width: 100vw !important;
        max-width: none !important;
      }

      ${desktopBreakpoint}{
        width: 800px !important;
        left: 50px !important;
        bottom: 50px !important;
        max-width: none !important;
      }
    }

    #onetrust-policy{
      margin-top: 0 !important;
      padding-bottom: 20px !important;
      padding-top: 25px !important;

      @media (min-width: 550px){
        margin-top: 20px !important;
        padding-bottom: 0px !important;
      }

      ${desktopBreakpoint}{
        margin-top: 0px !important;
        padding-bottom: 0px !important;
      }
    }

    #onetrust-policy-text{
      font-size: 11px !important;
      
      ${desktopBreakpoint}{
        font-size: 13px !important;
      }
    }

    .onetrust-pc-dark-filter {
      display: none !important;
    }
    
    .ot-sdk-container{
      background-color: ${colors.frappuccino};
      font-family: "messinaMono" !important;  
      position: relative !important;
      height: 215px !important;
      z-index: ${zIndex.surface + 1} !important;
      box-shadow: none !important;
      
      ${desktopBreakpoint}{
        height: 135px !important;
      }
    }

    .reject-btn-container{
      position: absolute !important;
      top: 5px !important;
      right: 0px !important;
      left: auto !important;
      height: auto !important;
      width: 45vw !important;
      padding: 0 !important;
      margin: 0 !important;
      z-index: ${zIndex.satan} !important;

      @media (min-width: 550px){
        width: 200px !important;
        top: 5px !important;
        right: 0 !important;
        left: auto !important;
      }

      ${desktopBreakpoint}{
        top: 0 !important;
        right: -21px !important;
        left: auto !important;
        width: 139px !important;
      }
    }

    .accept-btn-container{
      position: absolute !important;
      top: 5px !important;
      right: auto !important;
      left: 0px !important;
      height: auto !important;
      width: 45vw !important;
      padding: 0 !important;
      margin: 0 !important;
      z-index: ${zIndex.satan} !important;

      @media (min-width: 550px){
        top: 100px !important;
        width: 200px !important;
        right: 0 !important;
        left: auto !important;
      }

      ${desktopBreakpoint}{
        top: 0 !important;
        right: 118px !important;
        left: auto !important;
        width: 139px !important;
      }
    }

    #onetrust-reject-all-handler{
      height: 70px !important;
      width: 100% !important;
      padding: 0 !important;
      margin: 0 !important;
      min-width: auto !important;
      background-color: ${colors.black} !important;
      color: ${colors.frappuccino} !important;
      border: none !important;
      border-radius: 0 !important;
      

      ${desktopBreakpoint}{
        height: 139px !important;
      }

    }

    #onetrust-accept-btn-handler{
      height: 70px !important;
      width: 100% !important;
      padding: 0 !important;
      margin: 0 !important;
      min-width: auto !important;
      border: none !important;
      border-radius: 0 !important;
      opacity: 1 !important;
      
      
      ${desktopBreakpoint}{
        height: 139px !important;
      }
    }

    button:hover{
      opacity: 1 !important;
    }

  }

  button {
    border: none;
    padding: 0;
    outline: none;
    background: none;
    cursor: pointer;
    color: inherit;
    letter-spacing: inherit;
    font-size: inherit;
    line-height:inherit;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    background-color: transparent;
    padding: ${mobileVW(18)} ${mobileVW(18)};

    ${desktopBreakpoint}{
      padding: ${desktopVW(18)} ${desktopVW(18)};
    }
  }

  a{
    color: inherit; 
    text-decoration: inherit; 
  }

  .select{
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;
    outline: none;
  }

`;

export default Layout;
