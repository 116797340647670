import { useCartItems, useConvertCustomAttributes, useAreCustomAttributesIdentical, useSortCustomAttributes } from '../index'

export function useGetLineItem() {
  const cartItems = useCartItems()
  const convertCustomAttributes = useConvertCustomAttributes()
  const sortCustomAttributes = useSortCustomAttributes()
  const areCustomAttributesIdentical = useAreCustomAttributesIdentical()

  function getLineItem(variantId: string | number, customAttributes: Array<any>): ShopifyBuy.LineItem | null {
    if (cartItems.length < 1) {
      return null
    }
    const sortedConvertedCustomAttributes = sortCustomAttributes(convertCustomAttributes(customAttributes))
    const item = cartItems.find(cartItem => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      return cartItem.variant.id === variantId && areCustomAttributesIdentical(sortedConvertedCustomAttributes, sortCustomAttributes(convertCustomAttributes(cartItem.customAttributes)));
    })

    if (item == null) {
      return null
    }

    return item
  }

  return getLineItem
}
