// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-account-tsx": () => import("./../../../src/templates/Account.tsx" /* webpackChunkName: "component---src-templates-account-tsx" */),
  "component---src-templates-all-products-tsx": () => import("./../../../src/templates/AllProducts.tsx" /* webpackChunkName: "component---src-templates-all-products-tsx" */),
  "component---src-templates-collection-tsx": () => import("./../../../src/templates/Collection.tsx" /* webpackChunkName: "component---src-templates-collection-tsx" */),
  "component---src-templates-contact-tsx": () => import("./../../../src/templates/Contact.tsx" /* webpackChunkName: "component---src-templates-contact-tsx" */),
  "component---src-templates-find-us-tsx": () => import("./../../../src/templates/FindUs.tsx" /* webpackChunkName: "component---src-templates-find-us-tsx" */),
  "component---src-templates-general-tsx": () => import("./../../../src/templates/General.tsx" /* webpackChunkName: "component---src-templates-general-tsx" */),
  "component---src-templates-home-tsx": () => import("./../../../src/templates/Home.tsx" /* webpackChunkName: "component---src-templates-home-tsx" */),
  "component---src-templates-philosophy-tsx": () => import("./../../../src/templates/Philosophy.tsx" /* webpackChunkName: "component---src-templates-philosophy-tsx" */),
  "component---src-templates-product-tsx": () => import("./../../../src/templates/Product.tsx" /* webpackChunkName: "component---src-templates-product-tsx" */),
  "component---src-templates-search-tsx": () => import("./../../../src/templates/Search.tsx" /* webpackChunkName: "component---src-templates-search-tsx" */),
  "component---src-templates-text-tsx": () => import("./../../../src/templates/Text.tsx" /* webpackChunkName: "component---src-templates-text-tsx" */)
}

