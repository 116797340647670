import { useContext } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { LocaleContext } from "../../contexts";

export const useGlobalSettingsQuery = () => {
  // const { lang } = useContext(LocaleContext);
  
  const {
    allContentfulGlobalSettings: { edges },
  } = useStaticQuery(
    graphql`
      query settings {
        allContentfulGlobalSettings {
          edges {
            node {
              node_locale
              facebookPageLink
              pinterestPageLink
              youtubePageLink
              instagramPageLink
              instagramFooterHashLink
              isHeaderBlackOnDesktop {
                AllProducts
                Collection
                Home
                Product
                Text
                Contact
                Search
                Account
                FindUs
                Philosophy
              }
              countriesAllowedToPurchase 
              feedbackEmail
              pressEmail
              projectsEmail
              salesEmail
              openingHours {
                openingHoursMonday
                openingHoursTuesday
                openingHoursWednesday
                openingHoursThursday
                openingHoursFriday
                openingHoursSaturday
                openingHoursSunday
              }
              helpDeskPhoneLineNumber
              helpDeskWhatsappPhoneNumber
            }
          }
        }
      }
    `,
  )

  const settings = edges[0].node

  return settings
}