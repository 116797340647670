import { createPortal } from 'react-dom'

import { usePortal } from '../../hooks/index'
import { useLockScroll } from '../../hooks/index'

export const Portal = ({ id, children, toggled = true }) => {
  useLockScroll(toggled)
  const target = usePortal(id)
  return createPortal(children, target)
}
