import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { animated, useSpring, config } from 'react-spring';
import {
  desktopBreakpoint,
  colors,
  desktopVW,
  mobileVW,
  DesktopView,
  MobileView,
  SpringConfig,
} from '../../styles/index';
import { useGlobalDictionaryQuery } from '../../hooks';

interface Props {
  inputValue: string;
  inputSetValue: any;
  color: string;
  width: string;
  widthDesk: string;
  placeholder: string;
  fontSize: string;
  fontSizeDesk: string;
  onChangecallback: any;
  validation: string;
  SetContainErrors: any;
  inputType: string;
  inputName: string;
  required: boolean;
  hasSolidBackground: boolean;
  style: any;
}

export default function Input(props: Props) {
  const [isFocused, setIsFocused] = useState(false);
  const [isError, setIsError] = useState(false);
  const [valueInput, setValueInput] = useState('');

  const {
    inputValue: value,
    inputSetValue: setValue,
    inputType,
    inputName,
    color,
    width,
    widthDesk,
    placeholder,
    fontSize,
    fontSizeDesk,
    onChangecallback,
    validation,
    setContainErrors,
    children,
    required,
    hasSolidBackground,
    style,
    // validation,
  } = props;

  const handleChange = e => {
    setValue(e.target.value);
    setValueInput(e.target.value);
    onChangecallback(e);
  };

  const dictionary = useGlobalDictionaryQuery();

  const placeholderSpring = useSpring({
    transform: isFocused
      ? `translate(-13.3%, ${desktopVW(-29)}) scale(0.7)`
      : value.length > 0
      ? `translate(-13.3%, ${desktopVW(-29)}) scale(0.7)`
      : `translate(0%, ${desktopVW(0)}) scale(1)`,
    // color: isFocused ? colors.black : colors.lightGrey,
    config: SpringConfig.microInteraction,
  });

  const placeholderSpringMobile = useSpring({
    transform: isFocused
      ? `translateY(-15%, ${mobileVW(-29)}) scale(0.75)`
      : value.length > 0
      ? `translateY(-15%, ${mobileVW(-29)}) scale(0.75)`
      : `translate(0%, ${mobileVW(0)}) scale(1)`,
    // color: isFocused ? colors.black : colors.lightGrey,
    config: SpringConfig.microInteraction,
  });

  // const colorBorderBottom = () => {
  //   if (isFocused) {
  //     return colorBorder
  //   } else {
  //     if (value.length > 0) {
  //       if (isError) {
  //         return 'red'
  //       } else {
  //         return initialColorBorder ? initialColorBorder : 'rgba(0, 0, 0, 0.4)'
  //       }
  //     } else {
  //       return initialColorBorder ? initialColorBorder : 'rgba(0, 0, 0, 0.4)'
  //     }
  //   }
  // }

  useEffect(() => {
    if (value.length > 0) {
      switch (validation) {
        case 'onlyLetters':
          if (!/^[a-zA-Z\s]*$/.test(value)) {
            setIsError(true);
            setContainErrors(true);
          } else {
            setIsError(false);
            setContainErrors(false);
          }
          break;
        case 'onlyNumbers':
          if (!/^[0-9\s]*$/.test(value)) {
            setIsError(true);
            setContainErrors(true);
          } else {
            setIsError(false);
            setContainErrors(false);
          }
          break;
        case 'onlyEmail':
          if (value.length > 0) {
            if (!/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,10})$/.test(value)) {
              setIsError(true);
              setContainErrors(true);
            } else {
              setIsError(false);
              setContainErrors(false);
            }
          }
          break;
        case 'password':
          if (value.length > 0) {
            if (/^.*(?=.{6,20})(?=.*[a-z])(?=.*[A-Z]).*$/.test(value) === false) {
              // (?=.*\d)
              setIsError(true);
              setContainErrors(true);
            } else {
              setIsError(false);
              setContainErrors(false);
            }
          }
          break;
      }
    } else {
      setIsError(false);
      setContainErrors(true);
    }
  }, [isFocused, valueInput]);

  const errorText = () => {
    if (validation === 'onlyLetters') {
      return dictionary.pleaseEnterOnlyLatinLetters;
    }
    if (validation === 'onlyEmail') {
      return dictionary.pleaseEnterAValidEmailAddress;
    }
    if (validation === 'password') {
      return dictionary.atLeastNCharactersWithNCapitalLetter;
    }
  };

  return (
    <div style={{ position: 'relative' }}>
      <Wrapper>
        <DesktopView>
          <StyledPlaceholder
            style={{ ...placeholderSpring }}
            fontsizedesk={fontSizeDesk}
            hassolidbackground={hasSolidBackground}
          >
            {placeholder}
          </StyledPlaceholder>
        </DesktopView>
        <MobileView>
          <StyledPlaceholder
            style={{ ...placeholderSpringMobile }}
            fontSize={fontSize}
            hassolidbackground={hasSolidBackground}
          >
            {placeholder}
          </StyledPlaceholder>
        </MobileView>
        <StyledInput
          type={inputType}
          name={inputName}
          style={style}
          // value={}
          placeholder=""
          onChange={handleChange}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          required={required}
          autoComplete="off"
          color={color}
          width={width}
          widthDesk={widthDesk}
          fontSize={fontSize}
          fontsizedesk={fontSizeDesk}
          isFocused={isFocused}
          value={value}
        />
      </Wrapper>
      <ErrorMsg
        validation={validation}
        isError={isError}
        fontsizedesk={fontSizeDesk}
        isFocused={isFocused}
        hassolidbackground={hasSolidBackground}
      >
        {errorText()}
      </ErrorMsg>
      {children}
    </div>
  );
}

const Wrapper = styled.div`
  position: relative;
  //margin: ${mobileVW(60)} 0;
  font-family: 'messinaMonoLight';
  ${desktopBreakpoint}{
    //margin: ${desktopVW(32)} 0; 
  }
`;

const StyledInput = styled.input`
  font-family: 'messinaMono';
  order: 2;
  text-align: left;
  font-size: ${({ fontSize }) => fontSize};
  line-height: ${mobileVW(24)};
  border-radius: 0;
  border: none;
  flex: 1 0 auto;
  width: ${({ width }) => width};
  color: ${({ color }) => color};
  transition: 0.25s;
  //padding-bottom: ${({ fontSize }) => fontSize};
  //border: ${mobileVW(1)} solid ${colors.lightGrey};
  padding: ${mobileVW(18)} ${mobileVW(18)};

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: ${({ color }) => color};
    
  }

  &:-ms-input-placeholder {
    color: ${({ color }) => color};

  }

  &::-ms-input-placeholder {
    color: ${({ color }) => color};

  }

  &::-webkit-search-cancel-button{
    -webkit-appearance: none;
  }

  ${desktopBreakpoint}{
    order: 1;
    font-size: ${({ fontsizedesk }) => fontsizedesk};
    line-height: ${desktopVW(24)};
    width: ${({ widthDesk }) => widthDesk};
    //padding-bottom: ${({ fontsizedesk }) => fontsizedesk};
    //border: ${desktopVW(1)} solid ${colors.lightGrey};
    padding: ${desktopVW(18)} ${desktopVW(17)};
  }
`;

const StyledPlaceholder = styled(animated.div)`
  position: absolute;
  pointer-events: none;
  bottom: ${mobileVW(21)};
  left: ${mobileVW(18)};
  font-size: ${({ fontSize }) => fontSize};
  padding: 0 ${mobileVW(3)};
  background-color: ${({ hassolidbackground }) => hassolidbackground || 'transparent'};
  ${desktopBreakpoint} {
    bottom: ${desktopVW(22)};
    left: ${desktopVW(15)};
    font-size: ${({ fontsizedesk }) => fontsizedesk};
    padding: 0 ${desktopVW(3)};
  }
`;

const ErrorMsg = styled.p`
  font-family: 'messinaMonoLight';
  text-align: right;
  color: ${colors.tomato};
  position: absolute;
  font-size: ${mobileVW(13)};
  transform: ${`translateY(${mobileVW(5)})`};
  bottom: ${mobileVW(-2)};
  left: ${mobileVW(18)};
  translate: 0.2s;
  display: inline;
  background-color: ${({ hassolidbackground }) => hassolidbackground || 'transparent'};

  ${({ isError, isFocused }) => (isError && !isFocused ? `opacity: 1;` : `opacity: 0;`)}

  ${desktopBreakpoint}{
    bottom: ${desktopVW(11)};
    left: ${desktopVW(16)};
    //transform: ${`translateY(${desktopVW(10)})`};
    font-size: ${desktopVW(12)};
    padding: 0 ${desktopVW(3)};
  }
`;
