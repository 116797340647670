import React, { useContext, useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useSpring, animated } from 'react-spring';
import { desktopBreakpoint, colors, desktopVW, zIndex } from '../../../styles/index';
import { SettingsContext } from '../../../contexts';
import MenuChildDesktop from './MenuChildDesktop';
import SecondMenuDesktop from './SecondMenuDesktop';

import { useGlobalLayoutQuery } from '../../../hooks/index';

interface ProductInterface {
  productCategory: {
    category: string;
    subCategory: string;
  };
  shopifyData: {
    variants: [
      {
        compare_at_price: null | string;
        price: string;
      }
    ];
  };
  slug: string;
  thumbnailImages: [
    {
      fluid: any;
    }
  ];
  title: string;
  titleToShow: string;
}

interface Props {
  menuData: [];
  handleSubMenuClick: any;
  closeBothMenus: any;
}

export default function HeaderDesktopAsideMain(props: Props) {
  const { menuData, handleSubMenuClick, closeBothMenus } = props;
  const layout = useGlobalLayoutQuery();
  const {
    isMenuOpen,
    secondMenuVisible,
    setSecondMenuVisible,
    secondMenuContent,
    setSecondMenuContent,
  }: {
    isMenuOpen: any;
    secondMenuVisible: any;
    setSecondMenuVisible: any;
    secondMenuContent: any;
    setSecondMenuContent: any;
  } = useContext(SettingsContext);

  const [visible, setVisible] = useState<boolean>(false);
  const [items, setItems] = useState<{} | { menus: []; __typename: string }>({});
  const [secondMenuProducts, setSecondMenuProducts] = useState<any>({});
  const secondMenu = useRef();

  const closeSecondMenu = (): void => {
    setSecondMenuVisible(false);
    setSecondMenuContent(false);
    setTimeout(() => {
      setSecondMenuProducts({});
    }, 600);
  };

  useEffect(() => {
    if (!isMenuOpen) {
      setVisible(false);
    }
    if (isMenuOpen && menuData && menuData !== items) {
      setVisible(false);
      setTimeout(
        () => {
          setItems(menuData);
          setVisible(true);
        },
        visible ? 250 : 500
      );
    }
  }, [isMenuOpen, menuData]);

  useEffect(() => {
    if (!secondMenu.current) return;
    const callback = setTimeout(() => {
      secondMenu.current.scrollTop = 0;
    }, 350);
    return () => clearTimeout(callback);
  }, [secondMenuContent]);

  const leftContainerSpring = useSpring({
    transform: isMenuOpen ? 'translateX(0%)' : 'translateX(-100%)',
    config: {
      tension: 140,
      friction: 25,
    },
    delay: 200,
  });

  const secondContainerSpring = useSpring({
    transform: secondMenuVisible ? 'translateX(0%)' : 'translateX(-200%) ',
    config: {
      tension: 140,
      friction: 25,
    },
    delay: 200,
  });

  return (
    <>
      <SecondMenuWrapper style={{ ...secondContainerSpring }} ref={secondMenu}>
        <CloseButton
          type="button"
          style={{ paddingTop: '70px' }}
          aria-label="Close second menu"
          onClick={() => closeSecondMenu()}
        >
          {/* X */}
        </CloseButton>
        <SecondMenuDesktop
          data={secondMenuProducts}
          closeBothMenus={closeBothMenus}
          handleSubMenuClick={handleSubMenuClick}
          items={items}
        />
      </SecondMenuWrapper>

      <LeftMenuWrapper
        style={{
          ...leftContainerSpring,
          backgroundColor: layout.colorBackgroundSidebar
            ? layout.colorBackgroundSidebar
            : colors.black,
        }}
      >
        <BoxMenu>
          {items.menus &&
            items.menus.map(
              (
                { __typename, color, title, target, targetData, items, featuredProducts },
                index
              ) => {
                let getColor = 'default';
                if (color) {
                  getColor = color;
                }
                return (
                  <MenuChildDesktop
                    index={index}
                    color={getColor}
                    title={title || target.title}
                    key={index}
                    visible={visible}
                    target={target}
                    handleSubMenuClick={handleSubMenuClick}
                    targetData={targetData}
                    items={items}
                    products={featuredProducts}
                    typeName={__typename}
                    setSecondMenuProducts={setSecondMenuProducts}
                  />
                );
              }
            )}
        </BoxMenu>
      </LeftMenuWrapper>
    </>
  );
}

const LeftMenuWrapper = styled(animated.div)`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  min-height: ${desktopVW(600)};
  width: 34vw;
  z-index: ${zIndex.header - 1};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  color: white;
  transform: translateX(-100%);
  padding: ${desktopVW(132)} 0 0 ${desktopVW(57)};
`;

const SecondMenuWrapper = styled(animated.div)`
  position: fixed;
  top: 0;
  left: 34vw;
  height: 100vh;
  min-height: ${desktopVW(600)};
  width: 34vw;
  background-color: ${colors.frappuccino};
  z-index: ${zIndex.header - 1};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  color: white;
  transform: translateX(-200%);
  overflow: scroll;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const BoxMenu = styled.div`
  overflow-y: auto;
  width: 99%;
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: transparent;
    width: 12px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    border-radius: 10px;
    width: 7px;
  }
`;

const CloseButton = styled.button`
  position: fixed;
  top: ${desktopVW(0)};
  right: ${desktopVW(0)};
  width: ${desktopVW(50)};
  height: ${desktopVW(50)};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${colors.black};
  font-family: 'messinaMono';
  font-size: ${desktopVW(20)};

  ${desktopBreakpoint} {
  }
`;
