import React from 'react';
import styled from 'styled-components';
import { desktopBreakpoint, colors, desktopVW, mobileVW } from '../../styles/index';
import { useGlobalDictionaryQuery, useLocale, useProtectionProduct } from '../../hooks/index';

interface Props {
  lineItem: any;
  handleSkyltClick: any;
}

export default function CartSkylt(props: Props) {
  const { lineItem, handleSkyltClick } = props;

  const locale = useLocale();
  const dictionary = useGlobalDictionaryQuery();

  const findSkyltAttribute = attribute => attribute.key.includes('Extra protection');
  const skyltVariantType = lineItem.customAttributes
    .find(findSkyltAttribute)
    ?.key.split('-')[1]
    .trim();
  let typeProtectionDefault = true;
  if (skyltVariantType != 'Small' && skyltVariantType != 'Medium' && skyltVariantType != 'Large') {
    typeProtectionDefault = false;
  } else {
    typeProtectionDefault = true;
  }
  const skyltValue = lineItem.customAttributes.find(findSkyltAttribute)?.value;

  const protection = useProtectionProduct(skyltVariantType);
  const protectionPrice = protection ? Number(protection.price.amount) : 0;

  return (
    <SkyltBlock>
      <SkyltLeft>
        <SkyltLabel locale={locale} onClick={handleSkyltClick}>
          <SkyltCheckbox checked={skyltValue === 'added'} />
          <span>
            {typeProtectionDefault ? dictionary?.addProtectionText : 'Add Fabric Protection'}
          </span>
        </SkyltLabel>
        <SkyltTooltipTrigger>
          ?
          <SkyltTooltip>
            {typeProtectionDefault
              ? dictionary?.addProtectionDescription
              : 'An invisible matte layer is applied to protect the fabric against water, dirt and circles'}
          </SkyltTooltip>
        </SkyltTooltipTrigger>
      </SkyltLeft>
      <SkyltRight>+€{protectionPrice * lineItem.quantity}</SkyltRight>
    </SkyltBlock>
  );
}

const SkyltBlock = styled.div`
  grid-area: skyltBlock;
  font-family: 'messinaMono';
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: ${mobileVW(11)};
  padding: ${mobileVW(8)} ${mobileVW(10)};
  font-size: ${mobileVW(11)};
  border: ${mobileVW(1)} solid ${colors.lightGrey};

  ${desktopBreakpoint} {
    border: ${desktopVW(1)} solid ${colors.lightGrey};
    margin-top: ${desktopVW(10)};
    padding: ${desktopVW(10)};
    font-size: ${desktopVW(14)};
  }
`;

const SkyltLeft = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
`;

const SkyltRight = styled.span`
  font-family: 'messinaMonoLight';
  color: ${colors.subtleGrey};
  font-size: ${mobileVW(11)};
  ${desktopBreakpoint} {
    font-size: ${desktopVW(14)};
  }
`;

const SkyltLabel = styled.label<any>`
  font-family: 'messinaMonoLight';
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: ${mobileVW(9)};
  span {
    width: ${props => (props.locale === 'en' ? 'unset' : 'min-content')};
  }

  ${desktopBreakpoint} {
    font-size: ${desktopVW(11)};
  }
`;

const SkyltCheckbox = styled.div<any>`
  background: ${props => (props.checked ? 'black' : 'none')};
  border: ${mobileVW(1)} solid black;
  height: ${mobileVW(14)};
  width: ${mobileVW(14)};
  margin-right: ${mobileVW(10)};
  ${desktopBreakpoint} {
    border-width: ${desktopVW(1)};
    height: ${desktopVW(10)};
    width: ${desktopVW(10)};
    margin-right: ${desktopVW(10)};
  }
`;

const SkyltTooltip = styled.span`
  position: absolute;
  background: white;
  visibility: hidden;
  cursor: default;
  z-index: 1;
  font-size: ${mobileVW(11)};
  border: ${mobileVW(1)} solid black;
  bottom: ${mobileVW(11)};
  left: ${mobileVW(11)};
  width: ${mobileVW(200)};
  padding: ${mobileVW(10)};
  transform: translateX(-50%);
  ${desktopBreakpoint} {
    font-size: ${desktopVW(12)};
    border-width: ${desktopVW(1)};
    bottom: ${desktopVW(11)};
    left: ${desktopVW(11)};
    width: ${desktopVW(270)};
    padding: ${desktopVW(10)};
  }
`;

const SkyltTooltipTrigger = styled.div`
  cursor: pointer;
  border-radius: 50%;
  background: transparent;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border: ${mobileVW(1)} solid black;
  margin-left: ${mobileVW(8)};
  height: ${mobileVW(22)};
  width: ${mobileVW(22)};
  ${desktopBreakpoint} {
    border-width: ${desktopVW(1)};
    margin-left: ${desktopVW(8)};
    height: ${desktopVW(22)};
    width: ${desktopVW(22)};
  }
  &:hover ${SkyltTooltip} {
    visibility: visible;
  }
`;
