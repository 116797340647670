import { useContext } from 'react'
import { StoreContext } from '../../contexts'

export function useRemoveItemsFromCart() {
  const { client, cart, setCart } = useContext(StoreContext)

  async function removeItemsFromCart(lineItemIds: string[]) {
    if (cart == null || client == null) {
      throw new Error('Called removeItemsFromCart too soon')
    }

    if (lineItemIds.length < 1) {
      throw new Error('Must include at least one item to remove')
    }

    const newCart = await client.checkout.removeLineItems(cart.id, lineItemIds)
    setCart(newCart)
  }

  return removeItemsFromCart
}
