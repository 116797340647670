import { useContext } from 'react'
import { StoreContext } from '../../contexts'

export function useCartCount() {
  const { cart } = useContext(StoreContext)
  if (cart == null || cart.lineItems.length < 1) {
    return 0
  }

  const cartItemsWithoutSkylt = cart.lineItems.filter((item: any) => item.variant.id !== "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC80MDcxMjAwODIwNDQ0OA==")

  const count = cartItemsWithoutSkylt.reduce((totalCount, lineItem) => {
    return totalCount + lineItem.quantity
  }, 0)

  return count
}
