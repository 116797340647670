import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import Cookies from 'js-cookie';
import { redirectTo } from '@reach/router';
import { navigate } from 'gatsby';
import {
  desktopBreakpoint,
  colors,
  desktopVW,
  mobileVW,
  zIndex,
  topBarHeightDesktop,
  MobileView,
  DesktopView,
} from '../../../styles/index';
import { SettingsContext } from '../../../contexts';
import { useGlobalDictionaryQuery, useGlobalLayoutQuery } from '../../../hooks/index';
import Icons from '../../../utils/Icons';
import { useLocation } from '@reach/router';

interface Props {}

export default function TopBar(props: Props) {
  const {} = props;
  const location = useLocation();
  const {
    purchaseAllowed,
    renderTopBar,
    setRenderTopBar,
  }: {
    purchaseAllowed: any;
    renderTopBar: any;
    setRenderTopBar: any;
  } = useContext(SettingsContext);

  const dictionary = useGlobalDictionaryQuery();

  const layout = useGlobalLayoutQuery();

  const [isMobile, setIsMobile] = useState(
    !!(typeof window !== 'undefined' && window.innerWidth <= 1024)
  );
  const [topBarText, setTopBarText] = useState(false);

  const handleClose = () => {
    Cookies.set('_DBTB-closed', true, { expires: 365 });
    setRenderTopBar(false);
  };

  const TopBarComponent = () => {
    return (
      <div>
        <p onClick={() => navigate(layout?.topBarLink)}>{layout?.topBarContent}</p>
        {layout.topBarContentActiveForSecoundChance && (
          <p onClick={() => navigate(layout?.topBarLinkContentForSecoundChance)}>
            {layout.topBarContentForSecoundChance}
          </p>
        )}
      </div>
    );
  };

  useEffect(() => {
    const isClient = typeof window === 'object';
    if (!isClient) return false;
    const handleResize = () => {
      if (window.innerWidth <= 1024) {
        setIsMobile(true);
        return;
      }
      setIsMobile(false);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (layout?.topBarActive) {
      if (purchaseAllowed && purchaseAllowed.allowed) {
        if (Cookies.get('_DBTB-closed')) return setRenderTopBar(false);
        setRenderTopBar(true);
        setTopBarText(isMobile ? layout?.topBarContentShort : layout?.topBarContent);
      } else {
        setRenderTopBar(true);
        setTopBarText(dictionary?.shippingMessageTopBar?.replace('#', purchaseAllowed.country));
      }
    } else if (purchaseAllowed && purchaseAllowed.allowed) {
      setRenderTopBar(false);
    } else {
      setRenderTopBar(true);
      setTopBarText(dictionary?.shippingMessageTopBar?.replace('#', purchaseAllowed.country));
    }
  }, [purchaseAllowed, isMobile]);

  return (
    <>
      {renderTopBar && (
        <Wrapper>
          <Relative>
            <TopBarComponent />
            <CloseButton onClick={() => handleClose()}>
              <Icons name="closeWhite" />
            </CloseButton>
          </Relative>
        </Wrapper>
      )}
    </>
  );
}

const Wrapper = styled.div`
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors.black};
  padding: ${mobileVW(9)} 0;
  z-index: ${zIndex.satan};

  p {
    color: ${colors.frappuccino};
    font-size: ${mobileVW(12)};
    text-align: center;
    line-height: 140%;
    font-family: 'messinaMono', 'Courier New', Courier, monospace;
    letter-spacing: -0.05em;
    padding: 0 ${mobileVW(20)};
    cursor: pointer;
    ${desktopBreakpoint} {
      font-size: ${desktopVW(10)};
      padding: 0;
    }
  }

  ${desktopBreakpoint} {
    height: ${topBarHeightDesktop};
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${colors.black};
    padding: 0;
  }
`;

const CloseButton = styled.div`
  position: absolute;
  right: ${mobileVW(20)};
  top: 50%;
  color: ${colors.white};

  cursor: pointer;
  transform: translateY(-50%);

  ${desktopBreakpoint} {
    right: ${desktopVW(20)};
  }
`;

const Relative = styled.div`
  width: 100vw;
  height: auto;
  position: relative;

  ${desktopBreakpoint} {
  }
`;
