import React, {useState} from 'react'
import styled, {keyframes} from 'styled-components'
import { desktopBreakpoint, colors, desktopVW, mobileVW, zIndex, SpacerDesktop, SpacerMobile, SpringConfig } from '../../../styles/index'
import {useGlobalDictionaryQuery, useGlobalComponentsQuery} from '../../../hooks/index'
import Input from '../../shared/Input'
import Button from '../../shared/Button'
import {subscribe} from 'klaviyo-subscribe'
import {animated, useSpring, config} from 'react-spring'


export default function FooterSubscribeForm(props: Props) {

  const {
    
  } = props

  const dictionary = useGlobalDictionaryQuery()

  const global = useGlobalComponentsQuery()

  const [email, setEmail] = useState('')
  const [containErrors, SetContainErrors] = useState(false)
  const [clicked, setClicked] = useState(false)
  const [loading, setLoading] = useState(false)
  const [text, setText] = useState('')

  const handleContainerrors = state => {
    if (state) {
      SetContainErrors(true)
    }
    if (!state) {
      SetContainErrors(false)
    }
  }

  const reset = ()=>{setClicked(false); setLoading(false); setEmail(''); }

  const submitEmail = async e => {
    e.preventDefault()
    setLoading(true)
    setTimeout(()=>{
      subscribe(global?.klaviyoNewsletterListId, email).then(response =>{
        // console.log(response)
        setClicked(true)
        if(response.success){
          if(response.data.is_subscribed){
            setText(dictionary?.emailSubscribeAlreadyInListMsg);
          } else {
            setText(dictionary?.emailSubscribeSuccessMsg);
          }
          setTimeout(()=>reset(), 5000)
        } else {
          setText(dictionary?.emailSubscribeFailMsg);
          setTimeout(()=>reset(), 5000)
        }
      })
    }, 500)
}

  const buttonSpring = useSpring({
    transform: clicked ? 'translateY(120%)' : 'translateY(0%)',
    config: SpringConfig.microInteraction
  })

  const responseSpring = useSpring({
    transform: clicked ? 'translateY(0%)' : 'translateY(600%)',
    config: SpringConfig.microInteraction
  })

  return (
    <Wrapper
      onSubmit={e=>submitEmail(e)}
    >
      <Input
        inputValue={email}
        inputSetValue={setEmail}
        inputName='email'
        inputType='text'
        required={true}
        onChangecallback={e => setEmail(e.target.value)}
        placeholder='Email'
        color={colors.black}
        width={'100%'}
        widthDesk={desktopVW(800)}
        fontSizeDesk={desktopVW(28)}
        fontSize={mobileVW(20)}
        validation={'onlyEmail'}
        setContainErrors={handleContainerrors}
      />
      <OverflowHidden>
        <animated.div
          style={{...buttonSpring}}
        >
          <Button
            text={loading ? dictionary?.loading : dictionary?.send}
            Type='submit'
            AriaLabel={'Send'}
            width={'100%'}
            widthDesk={desktopVW(200)}
            colorBackground={'transparent'}
            color={colors.black}
            disableIt={containErrors}
            lefty={'potato'}
          />
        </animated.div>
        <ResponseMsg
        style={{...responseSpring}}
        >
          <Res>
            {text}
          </Res>
        </ResponseMsg>
      </OverflowHidden> 


    </Wrapper>
  )
}

const Wrapper = styled.form`
  display: flex;
  flex-direction: column;
  height: auto;
  width: 95.5vw;
  z-index: ${zIndex.surface};
  position: absolute;
  bottom: 0;
  left: ${mobileVW(10)};

  ${desktopBreakpoint}{
    flex-direction: row;
    bottom: ${desktopVW(35)};
    left: 0;
    width: 100vw;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 ${desktopVW(42)} 0 ${desktopVW(42)};

  }
`;

const Res = styled.p`
    font-family: 'messinaMonoLight';
    font-size: ${mobileVW(12)};

  ${desktopBreakpoint}{
    font-size: ${desktopVW(14)};
  }
`;

const OverflowHidden = styled.div`
  position: relative;
  overflow: hidden;

  ${desktopBreakpoint}{
  
  }
`;

const ResponseMsg = styled(animated.div)`
  text-align: center;
  transform: translateY(350%);
  position: absolute;
  top: ${mobileVW(15)};
  left: 0;
  width: 100%;

  ${desktopBreakpoint}{
    top: ${desktopVW(20)};
  }
`;

