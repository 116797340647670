import { useContext } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { useLocation } from '@reach/router';
import { LocaleContext } from '../../contexts';

export const useGlobalLayoutQuery = () => {
  const { lang } = useContext(LocaleContext);

  const {
    allContentfulGlobalLayout: { edges },
  } = useStaticQuery(
    graphql`
      query globalLayout {
        allContentfulGlobalLayout {
          edges {
            node {
              node_locale
              footerText {
                footerText
              }
              lookbookFooter {
                description
                title
                fluid(maxWidth: 720, quality: 50) {
                  ...GatsbyContentfulFluid_withWebp_noBase64
                }
              }
              colorPriceSale
              colorMenuInSectionHero
              colorMenu
              colorMenuHeaderMobile
              colorMenuInSidebar
              colorMenuHeaderWhenScrollMobile
              colorBackgroundSidebar
              colorUnderlineMenuWhenHover
              colorUnderlineMenuInSidebar
              colorMenuHeaderWhenOpenSidebarMenu
              colorMenuHeaderOtherThanOnTheHomePage
              menuHeader {
                ... on ContentfulComponentMenuGroup {
                  title
                  menus {
                    ... on ContentfulComponentLink {
                      target {
                        ... on ContentfulPageCollection {
                          title
                          slug
                        }
                        ... on ContentfulPagePhilosophy {
                          slug
                          title
                        }
                        ... on ContentfulPageGeneral {
                          slug
                          title
                        }
                      }
                    }
                    ... on ContentfulComponentMenu {
                      title
                      target {
                        slug
                      }
                      targetData
                      items {
                        title
                        target {
                          ... on ContentfulPageAllProducts {
                            title
                            slug
                          }
                        }
                        data
                      }
                    }
                  }
                }
                ... on ContentfulComponentLink {
                  title
                  __typename
                  target {
                    ... on ContentfulPageFindUs {
                      slug
                    }
                    ... on ContentfulPageContact {
                      slug
                    }
                    ... on ContentfulPageCollection {
                      slug
                    }
                    ... on ContentfulPageAllProducts {
                      slug
                    }
                    ... on ContentfulPageGeneral {
                      slug
                    }
                  }
                }
              }
              menuFooter {
                title
                menus {
                  ... on ContentfulComponentLink {
                    title
                    target {
                      ... on ContentfulPageAllProducts {
                        slug
                      }
                      ... on ContentfulPageContact {
                        slug
                      }
                      ... on ContentfulPageCollection {
                        slug
                      }
                      ... on ContentfulPageText {
                        slug
                      }
                      ... on ContentfulPageGeneral {
                        slug
                      }
                    }
                  }
                }
              }
              notFoundPageImages {
                fluid(maxWidth: 1000, quality: 70) {
                  ...GatsbyContentfulFluid_withWebp_noBase64
                }
              }
              topBarContent
              topBarContentShort
              topBarContentForSecoundChance
              topBarContentActiveForSecoundChance
              topBarLinkContentForSecoundChance
              topBarActive
              topBarLink
            }
          }
        }
      }
    `
  );

  const filteredGlobalLayoutQuery = edges.filter(
    ({ node: { node_locale } }) => node_locale === lang
  );

  // if no match between node_locale and lang --> just return the first one (for static 404 page, there is no node_locale context)
  const globalLayout =
    filteredGlobalLayoutQuery.length === 0 ? edges[0].node : filteredGlobalLayoutQuery[0]?.node;
  return globalLayout;
};
