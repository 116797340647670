import { useContext } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { LocaleContext } from '../../contexts'

export const useSearchQuery = () => {
  const { lang } = useContext(LocaleContext);

  const {
    allContentfulPageSearch: { edges },
  } = useStaticQuery(
    graphql`
      query search {
        allContentfulPageSearch {
          edges {
            node {
              node_locale
              slug
            }
          }
        }
      }
    `
  );

  let filteredSearchQuery = edges.filter(
    ({ node: { node_locale } }) => node_locale === lang,
  )

  let search = filteredSearchQuery[0]?.node
  return search
}