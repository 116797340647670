// @ts-nocheck
/* eslint-disable */

import React, { useContext, useEffect, useState } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { useLocation } from '@reach/router';
import { useGlobalDictionaryQuery, useGlobalSettingsQuery } from '../../hooks';
import { desktopBreakpoint, colors, desktopVW, mobileVW, zIndex } from '../../styles/index';
import Icons from '../../utils/Icons';
import LinkTool from '../shared/LinkTool';

const GlobalStyle = createGlobalStyle`
  body {
    #helpcrunch-container {
      width: 500px;
    }
  }
`;

export const HelpDeskMenu = () => {
  const [open, setOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [hide, setHide] = useState(false);
  const [helpCrunchReady, setHelpCrunchReady] = useState(false);
  const [currentTimeInSeconds, setCurrentTimeInSeconds] = useState('');
  const {
    instagramPageLink,
    helpDeskWhatsappPhoneNumber,
    helpDeskPhoneLineNumber,
    openingHours,
  } = useGlobalSettingsQuery();
  const dictionary = useGlobalDictionaryQuery();
  const location = useLocation();

  useEffect(() => {
    if (window?.location?.href?.includes('shelfmate-app')) {
      setHide(true);
    } else {
      setHide(false);
    }
  }, []);
  useEffect(() => {
    HelpCrunch('onReady', () => {
      setHelpCrunchReady(true);
      HelpCrunch('hideChatWidget');
      /*    const observer = new MutationObserver(function (mutations) { // code to open helpCrunch fullscreen
              mutations.forEach((mutationRecord) => {
                //console.log('style changed! ', mutationRecord);
                if (mutationRecord.target.style.width != "500px") {
                  mutationRecord.target.style.width = "500px";
                }
                if (mutationRecord.target.style.height != "100vh") {
                  mutationRecord.target.style.height = "100vh";
                }
                const helpCrunchChat = mutationRecord.target.contentWindow.document.querySelector(".helpcrunch-chat");
                console.log(helpCrunchChat);
                if (helpCrunchChat) {
                  helpCrunchChat.style.maxHeight = "100vh";
                  helpCrunchChat.style.maxWidth = "100vw";
                  helpCrunchChat.style.height = "100%";
                  helpCrunchChat.style.width = "100%";
                  helpCrunchChat.style.margin = "0";
                }
              })
            })
            const helpCrunchIframe = document.getElementsByName("helpcrunch-iframe")[0];
            observer.observe(helpCrunchIframe, { attributes: true, attributeFilter: ["style"] }); */
    });
  }, []);

  const openHelpCrunchWidget = () => {
    if (typeof HelpCrunch !== 'undefined') {
      HelpCrunch('openChat');
      // document.querySelector('#launcher').style.opacity = 0
    }
  };

  useEffect(() => {
    setCurrentTimeInSeconds(new Date().getHours() * 3600 + new Date().getMinutes() * 60);
    if (!window?.location?.href?.includes('shelfmate-app')) {
      if ((location.pathname == '/en') | '/nl') {
        setTimeout(() => setShow(true), 5000);
      } else {
        setTimeout(() => setShow(true), 3000);
      }
    }

    const refreshTimeInterval = setInterval(
      () => setCurrentTimeInSeconds(new Date().getHours() * 3600 + new Date().getMinutes() * 60),
      60000
    );
    return () => clearInterval(refreshTimeInterval);
  }, [location]);

  const getOpeningHoursArray = () => {
    const date = new Date();
    const currentDay = date.toLocaleDateString('en-US', { weekday: 'long' });
    switch (currentDay) {
      case 'Monday':
        return openingHours.openingHoursMonday;
      case 'Tuesday':
        return openingHours.openingHoursTuesday;
      case 'Wednesday':
        return openingHours.openingHoursWednesday;
      case 'Thursday':
        return openingHours.openingHoursThursday;
      case 'Friday':
        return openingHours.openingHoursFriday;
      case 'Saturday':
        return openingHours.openingHoursSaturday;
      case 'Sunday':
        return openingHours.openingHoursSunday;
      default:
        return '';
    }
  };

  const getTimeInSeconds = time => {
    const trimmedTime = time.trim();
    const hours = Number(trimmedTime.split(':')[0]);
    const minutes = Number(trimmedTime.split(':')[1]);
    return hours * 3600 + minutes * 60;
  };

  const getChatStatus = () => {
    const todayOpeningHours = getOpeningHoursArray();
    if (todayOpeningHours?.length >= 0) {
      for (let i = 0; i < todayOpeningHours.length; i++) {
        const parsedOpeningTime = getTimeInSeconds(todayOpeningHours[i].split('-')[0].trim());
        const parsedClosingTime = getTimeInSeconds(
          todayOpeningHours[i]
            .split('-')[1]
            .trim()
            .split(' ')[0]
            .trim()
        );
        const hourColor = todayOpeningHours[i]
          .split('-')[1]
          .trim()
          .split(' ')[1]
          .trim();
        if (
          parsedOpeningTime <= currentTimeInSeconds &&
          currentTimeInSeconds <= parsedClosingTime
        ) {
          // setMessageColorStatus(hourColor);
          return hourColor.toLowerCase();
        }
      }
    } else {
      return 'red';
    }
    return 'red';
  };

  //=========================================================Style================================================

  const HelpDeskSliderButton = styled.button`
    background-color:${dictionary.colorButtonTabContact};
    color:${dictionary.textColorButtonTabContact};
    display: flex;
    //display: ${props => (props.show ? 'flex' : 'none')};
    visibility: ${props => (props.show ? 'visibile' : 'hidden')};
    opacity: 0;
    ${props => props.show && `opacity: 1`};
    pointer-events: ${props => (props.show ? 'all' : 'none')};
    transition: opacity 0.8s;
    justify-content: center;
    align-items: center;
    position: absolute;
    height: ${mobileVW(130)};
    width: ${mobileVW(50)};
    right: 0px;
    padding: 2px 2px 0 0;
    top: 47%;
    transform: translateY(-50%);
    z-index:  ${({
      // @ts-ignore
      hidden,
    }) => (hidden ? '-1' : '100001')};
    border: 0px;
    border-right: none;
    left: -${mobileVW(50)};
    div {
      font-size: ${mobileVW(17)};
      font-family: "messinaMono" !important;
      letter-spacing: ${mobileVW(1)};
      font-weight: normal;
      writing-mode: vertical-lr;
      transform: rotate(180deg);
    }
    ${desktopBreakpoint} {
      height: 160px;
      width: 65px;
      left: -65px;
      top: 50%;
      div {
        margin-right: 4px;
        font-size: 20px;
        letter-spacing: unset;
      }
    }
`;

  const HelpDeskSlider = styled.div`
    font-family: 'messinaMono', 'Courier New', Courier, monospace;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    right: 0;
    margin-right: ${props => (props.open ? '0' : '-100vw')};
    z-index: 100002;
    transition: 0.7s;
    text-align: center;
    background-color: ${dictionary.backgroundSidebarContact};
    ${desktopBreakpoint} {
      justify-content: flex-start;
      text-align: left;
      width: 500px;
      background-color: ${dictionary.backgroundSidebarContact};
      margin-right: ${props => (props.open ? '0' : '-505px')};
    }
  `;

  //=========================================================End Style================================================

  return (
    <>
      {open && <CloseOverlay onClick={() => setOpen(false)} />}
      <HelpDeskSlider open={open}>
        <GlobalStyle />
        <HelpDeskSliderButton hidden={hide} show={show} onClick={() => setOpen(!open)}>
          <div>{open ? dictionary.helpDeskClose : dictionary.helpDeskContact}</div>
        </HelpDeskSliderButton>
        <HelpDeskContainer>
          <HelpDeskHeader>
            <HeaderTitle style={{ color: dictionary.colorButtonInSidebarContact }}>
              {dictionary.helpDeskContactDbodhi}
            </HeaderTitle>
            <CloseText
              style={{ color: dictionary.colorButtonInSidebarContact }}
              onClick={() => setOpen(false)}
            >
              {dictionary.helpDeskClose}
            </CloseText>
          </HelpDeskHeader>
          <HelpDeskBody>
            <HelpDeskMain>
              <HelpDeskButton
                style={{ color: dictionary.colorMenuContactInSidebar }}
                onClick={() => {
                  openHelpCrunchWidget();
                  setOpen(false);
                }}
              >
                {dictionary.helpDeskChatWithUs}
              </HelpDeskButton>
              <HelpDeskText style={{ color: dictionary.colorDescriptionTitleInContactSidebar }}>
                <StatusDot status={getChatStatus()} />
                {getChatStatus() ? dictionary.helpDeskWeAreOnline : dictionary.helpDeskWeAreOffline}
              </HelpDeskText>
              <HelpDeskButton
                style={{ color: dictionary.colorMenuContactInSidebar }}
                onClick={() => {
                  window.open(`https://wa.me/${helpDeskWhatsappPhoneNumber}`);
                }}
              >
                {dictionary.helpDeskWhatsappText}
              </HelpDeskButton>
              <HelpDeskText style={{ color: dictionary.colorDescriptionTitleInContactSidebar }}>
                <StatusDot status={getChatStatus()} />
                {getChatStatus()
                  ? dictionary.helpDeskWeAreAvailable
                  : dictionary.helpDeskWeAreUnavailable}
              </HelpDeskText>
              <HelpDeskButton
                style={{ color: dictionary.colorMenuContactInSidebar }}
                onClick={() => {
                  window.open(`tel:${helpDeskPhoneLineNumber}`);
                }}
              >
                {dictionary.helpDeskCallUs}
              </HelpDeskButton>
              <HelpDeskText style={{ color: dictionary.colorDescriptionTitleInContactSidebar }}>
                <StatusDot status={getChatStatus()} />
                {getChatStatus()
                  ? dictionary.helpDeskWeAreAvailablePhoneCall
                  : dictionary.helpDeskWeAreUnavailablePhoneCall}
              </HelpDeskText>
              <LinkTool to="/contact">
                <HelpDeskButton
                  style={{ color: dictionary.colorMenuContactInSidebar }}
                  onClick={() => setOpen(false)}
                >
                  {dictionary.helpDeskSendUsAMessage}
                </HelpDeskButton>
              </LinkTool>
              <HelpDeskText style={{ color: dictionary.colorDescriptionTitleInContactSidebar }}>
                {dictionary.helpDeskResponseTime}
              </HelpDeskText>
              <a href={instagramPageLink} target="__blank">
                <HelpDeskButton style={{ color: dictionary.colorMenuContactInSidebar }}>
                  {dictionary.helpDeskInstagramButtonText}
                </HelpDeskButton>
              </a>
              <HelpDeskText style={{ color: dictionary.colorDescriptionTitleInContactSidebar }}>
                {dictionary.helpDeskInstagramResponseTime}
              </HelpDeskText>
            </HelpDeskMain>
            {/* <HelpDeskAsterisk>{dictionary.helpDeskFooterText}</HelpDeskAsterisk> */}
          </HelpDeskBody>
          <HelpDeskFooter id="helpdesk-slider">
            <FooterTitle>{dictionary.helpDeskContactDbodhi}</FooterTitle>
          </HelpDeskFooter>
          {/* <CloseButton onClick={() => setOpen(false)}>CLOSE</CloseButton> */}
        </HelpDeskContainer>
      </HelpDeskSlider>
    </>
  );
};

const CloseText = styled.a`
  text-decoration: underline;
  font-size: ${mobileVW(16)};
  ${desktopBreakpoint} {
    font-size: 18px;
  }
  :hover {
    cursor: pointer;
  }
`;

const CloseOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 100000;
`;

const HeaderTitle = styled.div`
  display: none;
  ${desktopBreakpoint} {
    display: block;
  }
`;

const FooterTitle = styled.div`
  display: block;
  color: ${colors.subtleGrey};
  ${desktopBreakpoint} {
    display: none;
  }
`;

const StatusDot = styled.div`
  width: ${mobileVW(7)};
  height: ${mobileVW(7)};
  border-radius: 50%;
  margin-right: 10px;
  flex-shrink: 0;
  background-color: ${props =>
    props.status === 'green' ? 'green' : props.status === 'orange' ? 'orange' : 'red'};
  ${desktopBreakpoint} {
    width: ${desktopVW(7)};
    height: ${desktopVW(7)};
  }
`;

const HelpDeskHeader = styled.div`
  font-size: ${mobileVW(25)};
  padding: 42px 0 25px 0;
  display: flex;
  justify-content: center;
  color: ${colors.subtleGrey};
  ${desktopBreakpoint} {
    width: 100%;
    justify-content: space-between;
    font-size: 18px;
  }
`;

const HelpDeskDivider = styled.hr`
  width: 100%;
`;

const HelpDeskContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 90%;
  max-width: ${mobileVW(550)};
  ${desktopBreakpoint} {
    align-items: flex-start;
    width: 380px;
  }
`;

const HelpDeskBody = styled.div`
  overflow: hidden;
  padding-top: 15px;
  padding-left: 4px;
  width: ${mobileVW(350)};
  padding-bottom: ${mobileVW(80)};
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  overflow-y: auto;
  justify-content: center;
  ${desktopBreakpoint} {
    align-items: flex-start;
    padding-bottom: 0;
    width: 100%;
  }
`;

const HelpDeskMain = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  ${desktopBreakpoint} {
    align-items: flex-start;
    width: 100%;
  }
`;

const HelpDeskCloseButton = styled.div`
  cursor: pointer;
`;

const HelpDeskButton = styled.div`
  border: 0px;
  color: ${colors.frappuccino};
  width: ${mobileVW(455)};
  font-size: ${mobileVW(20)};
  padding: 0;
  margin: 0;
  transition: 0.3s;
  &:hover {
    cursor: pointer;
    user-select: none;
  }
  ${desktopBreakpoint} {
    width: 100%;
    font-size: 25px;
  }
`;

const HelpDeskText = styled.div`
  display: flex;
  align-items: baseline;
  color: ${colors.subtleGrey};
  font-size: ${mobileVW(12)};
  margin-bottom: 40px;
  ${desktopBreakpoint} {
    align-items: baseline;
    font-size: 16px;
  }
`;

const HelpDeskAsterisk = styled.div`
  color: ${colors.subtleGrey};
  font-size: ${mobileVW(13)};
  margin-bottom: 3px;
  ${desktopBreakpoint} {
    font-size: 16px;
  }
`;

const HelpDeskFooter = styled.div`
  margin-bottom: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const WidgetButton = styled.button``;

const CloseButton = styled.button``;
