import { useLayoutEffect } from 'react'


export function useLockScroll(toggle: boolean) {
  useLayoutEffect(() => {
    // Get original body overflow
    if (toggle) {
      const originalStyle = window.getComputedStyle(document.body).overflow
      // Prevent scrolling on mount
      document.body.style.overflow = 'hidden'
      // Re-enable scrolling when component unmounts
      return () => (document.body.style.overflow = originalStyle)
    }
  }, [toggle]) // Empty array ensures effect is only run on mount and unmount
}
