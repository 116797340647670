import { navigate, useLocation } from '@reach/router';
import React, { useContext, useEffect, useState } from 'react';
import { animated, useSpring } from 'react-spring';
import styled from 'styled-components';
import { SettingsContext } from '../../contexts';
import { useGlobalLayoutQuery, useGlobalSettingsQuery } from '../../hooks/index';
import { useLang } from '../../hooks/layout/useLang';
import { useLocale } from '../../hooks/layout/useLocale';
import {
  DesktopView,
  MobileView,
  colors,
  desktopBreakpoint,
  desktopVW,
  mobileVW,
} from '../../styles/index';
import Icons from '../../utils/Icons';
import LinkTool from '../shared/LinkTool';
import FooterLookbook from './footer/FooterLookbook';
import FooterSocialBar from './footer/FooterSocialBar';
import FooterSubscribe from './footer/FooterSubscribe';

const FooterMenuchild = title => {
  const [borderSpring, setBorderSpring] = useSpring(() => ({
    transform: 'translateX(-105%)',
    config: {
      tension: 210,
      friction: 34,
    },
  }));

  return (
    <BorderOnHoverWrapper
      onMouseMove={() => setBorderSpring({ transform: 'translateX(0%)' })}
      onMouseLeave={() => setBorderSpring({ transform: 'translateX(-105%)' })}
    >
      {title.title}
      <Border style={{ ...borderSpring }} />
    </BorderOnHoverWrapper>
  );
};

const Border = styled(animated.div)`
  position: absolute;
  bottom: ${desktopVW(0)};
  left: 0;
  height: ${desktopVW(1)};
  background-color: ${colors.lightGrey};
  width: 100%;

  ${desktopBreakpoint} {
  }
`;

const BorderOnHoverWrapper = styled.div`
  position: relative;
  overflow: hidden;
  display: inline-block;

  ${desktopBreakpoint} {
  }
`;

export default function Footer() {
  const [url, setUrl] = useState('');
  const layout = useGlobalLayoutQuery();

  const {
    facebookPageLink,
    pinterestPageLink,
    youtubePageLink,
    instagramPageLink,
  }: {
    facebookPageLink: string;
    pinterestPageLink: string;
    youtubePageLink: string;
    instagramPageLink: string;
  } = useGlobalSettingsQuery();

  const {
    resetFilters,
  }: {
    resetFilters: () => void;
  } = useContext(SettingsContext);

  const allLocaleSlugs = useLang();
  const langs = Object.keys(allLocaleSlugs);
  const currLocale = useLocale();
  const location = useLocation();

  function getLanguageEnglish(data: string) {
    switch (data) {
      case (data = 'en'):
        return 'English';
        break;
      case (data = 'nl'):
        return 'Engels';
        break;
      case (data = 'fr'):
        return 'Anglais';
        break;
      default:
        return 'English';
    }
  }

  function getLanguageDutch(data: string) {
    switch (data) {
      case (data = 'en'):
        return 'Dutch';
        break;
      case (data = 'nl'):
        return 'Nederland';
        break;
      case (data = 'fr'):
        return 'Néerlandais';
        break;
      default:
        return 'Nederland';
    }
  }

  function getLanguageFrance(data: string) {
    switch (data) {
      case (data = 'en'):
        return 'French';
        break;
      case (data = 'nl'):
        return 'Frans';
        break;
      case (data = 'fr'):
        return 'French';
        break;
      default:
        return 'French';
    }
  }
  useEffect(() => {
    // Akses window.location.href di sini
    setUrl(window?.location?.href);
  }, []);

  if (url?.includes('shelfmate-app')) {
    return null;
  } else {
    return (
      <div id="footer">
        <FooterLookbook />
        <FooterSocialBar />
        <FooterSubscribe />
        <Wrapper>
          <FooterContacts dangerouslySetInnerHTML={{ __html: layout?.footerText.footerText }} />
          <FooterHeader>
            <LogoWrapper>
              <Icons name="logo" />
            </LogoWrapper>
            <SocialWrapper>
              <a
                href={instagramPageLink}
                target="_blank"
                rel="noopener noreferrer"
                aria-label="instagram link"
              >
                <Icons name="instagram" />
              </a>
              <a
                href={pinterestPageLink}
                target="_blank"
                rel="noopener noreferrer"
                aria-label="pinterest link"
              >
                <Icons name="pinterest" />
              </a>
              <a
                href={facebookPageLink}
                target="_blank"
                rel="noopener noreferrer"
                aria-label="facebook link"
              >
                <Icons name="facebook" />
              </a>
              <a
                href={youtubePageLink}
                target="_blank"
                rel="noopener noreferrer"
                aria-label="youtube link"
              >
                <Icons name="youtube" />
              </a>
            </SocialWrapper>
          </FooterHeader>
          <FooterBody>
            {layout?.menuFooter.map(({ title, menus }, index) => (
              <MenuWrapper key={title + index}>
                <FooterMenuTitle>{title}</FooterMenuTitle>
                <FooterMenu>
                  {menus.map(({ title, target }, index) => (
                    <FooterMenuChild key={title + index}>
                      <LinkTool to={`/${target?.slug}/`}>
                        <FooterMenuchild title={title} />
                      </LinkTool>
                    </FooterMenuChild>
                  ))}
                </FooterMenu>
              </MenuWrapper>
            ))}
            <LangContainer>
              <FooterMenuTitle>
                {currLocale === 'en' ? 'Language' : currLocale === 'nl' ? 'Taal' : 'Langue'}
              </FooterMenuTitle>
              {langs.map(lang => {
                let langFormatted: string;
                const english = getLanguageEnglish(currLocale);
                const dutch = getLanguageDutch(currLocale);
                const france = getLanguageFrance(currLocale);

                if (lang === 'en') {
                  langFormatted = english;
                } else if (lang === 'nl') {
                  langFormatted = dutch;
                } else if (lang === 'fr') {
                  langFormatted = france;
                }

                const newUrl =
                  allLocaleSlugs[lang] === 'account'
                    ? `${lang}/`
                    : `${lang}/${allLocaleSlugs[lang]}`;
                return (
                  <FooterMenuChild key={lang}>
                    <div
                      onClick={() => {
                        navigate(`/${newUrl}`);
                        resetFilters();
                      }}
                    >
                      <FooterMenuchild title={langFormatted} />
                    </div>
                  </FooterMenuChild>
                );
              })}
            </LangContainer>
          </FooterBody>
          <FooterSignature>
            <p className="FooterSignature__copyright">
              {`All rights reserved. © ${new Date().getFullYear()} d-Bodhi`}
            </p>
            <MobileView>
              <p className="FooterSignature__sign">
                Website by <br />
                {/* <span>
                  <a
                    href="https://reclaem.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Facebook"
                  >
                    Reclaem
                  </a>
                </span> */}
                {/* | */}
                <span>
                  <a
                    href="https://numbered.studio/"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Facebook"
                  >
                    Numbered
                  </a>
                </span>
                |
                <span>
                  <a
                    href="https://askphill.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Facebook"
                  >
                    Ask Phill
                  </a>
                </span>
              </p>
            </MobileView>
            <DesktopView>
              <p className="FooterSignature__sign">
                Website by
                {/* <span>
                  <a
                    href="https://reclaem.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Facebook"
                  >
                    Reclaem
                  </a>
                </span> */}
                {/* | */}
                <span>
                  <a
                    href="https://numbered.studio/"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Facebook"
                  >
                    Numbered
                  </a>
                </span>
                |
                <span>
                  <a
                    href="https://askphill.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Facebook"
                  >
                    Ask Phill
                  </a>
                </span>
              </p>
            </DesktopView>
          </FooterSignature>
        </Wrapper>
      </div>
    );
  }
}

const Wrapper = styled.footer`
  width: 100vw;
  height: auto;
  background-color: ${colors.black};
  position: relative;
  height: auto;
  min-height: ${mobileVW(300)};

  ${desktopBreakpoint} {
    min-height: ${desktopVW(620)};
  }
`;

const FooterContacts = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  color: ${colors.lightGrey};
  font-family: 'messinaMono';
  letter-spacing: -0.05em;
  font-size: ${mobileVW(18)};
  line-height: 140%;
  top: ${mobileVW(128)};
  left: ${mobileVW(30)};

  a {
    color: ${colors.white};
  }

  ${desktopBreakpoint} {
    top: ${desktopVW(104)};
    left: ${desktopVW(61)};
    font-size: ${desktopVW(14)};
  }
`;

const FooterHeader = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0;

  ${desktopBreakpoint} {
    padding: 0;
  }
`;

const LogoWrapper = styled.div`
  padding: ${mobileVW(64)} 0 0 ${mobileVW(34)};

  ${desktopBreakpoint} {
    padding: ${desktopVW(60)} 0 0 ${desktopVW(63)};
  }
`;

const SocialWrapper = styled.div`
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-column-gap: ${mobileVW(23)};
  padding: ${mobileVW(75)} ${mobileVW(35)} 0 0;

  ${desktopBreakpoint} {
    padding: ${desktopVW(67)} ${desktopVW(62)} 0 0;
    grid-column-gap: ${desktopVW(13)};
  }
`;

const FooterBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${mobileVW(190)} 0 ${mobileVW(100)} ${mobileVW(30)};

  ${desktopBreakpoint} {
    flex-direction: row;
    padding: ${desktopVW(66)} 0 ${desktopVW(200)} ${desktopVW(450)};
  }
`;

const MenuWrapper = styled.div`
  ${desktopBreakpoint} {
    width: ${desktopVW(230)};
  }
`;

const FooterMenuTitle = styled.p`
  color: ${colors.white};
  font-family: 'messinaMono';
  letter-spacing: -0.05em;
  font-size: ${mobileVW(18)};
  padding-bottom: ${mobileVW(20)};
  padding-top: ${mobileVW(20)};

  ${desktopBreakpoint} {
    font-size: ${desktopVW(14)};
    padding-bottom: ${desktopVW(28)};
    padding-top: 0;
  }
`;

const FooterMenu = styled.div`
  display: flex;
  flex-direction: column;
  color: ${colors.lightGrey};
  font-family: 'messinaMono';
  letter-spacing: -0.05em;
  font-size: ${mobileVW(18)};

  ${desktopBreakpoint} {
    font-size: ${desktopVW(14)};
  }
`;

const FooterMenuChild = styled.div`
  ${desktopBreakpoint} {
    margin-bottom: ${desktopVW(10)};
  }
`;

const FooterSignature = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 ${mobileVW(30)} ${mobileVW(20)} ${mobileVW(30)};

  .FooterSignature {
    &__copyright {
      color: rgba(255, 255, 255, 0.3);
      font-family: 'messina';
      letter-spacing: -0.05em;
      font-size: ${mobileVW(14)};
      width: ${mobileVW(120)};
      ${desktopBreakpoint} {
        font-size: ${desktopVW(12)};
        width: auto;
      }
    }
    &__sign {
      color: rgba(255, 255, 255, 0.3);
      font-family: 'messina';
      letter-spacing: -0.05em;
      font-size: ${mobileVW(14)};
      width: ${mobileVW(200)};
      text-align: right;
      a {
        white-space: nowrap;
        color: white;
        &:before {
          content: ' ';
        }
        &:after {
          content: ' ';
        }
      }
      span {
        ${desktopBreakpoint} {
          margin: 0 ${desktopVW(3)};
        }
      }
      ${desktopBreakpoint} {
        font-size: ${desktopVW(12)};
        width: auto;
      }
    }
  }

  ${desktopBreakpoint} {
    padding: ${desktopVW(65)} ${desktopVW(55)};
  }
`;

const TextWrapper = styled.div`
  padding: ${mobileVW(20)} 0 ${mobileVW(60)} ${mobileVW(32)};
  p {
    font-size: ${mobileVW(16)};
    font-family: 'messinaMono';
    color: ${colors.subtleGrey};
    line-height: ${mobileVW(25)};
  }
  a {
    font-size: ${mobileVW(16)};
    font-family: 'messinaMono';
    color: ${colors.lightGrey};
    line-height: ${mobileVW(25)};
  }

  ${desktopBreakpoint} {
    padding: ${desktopVW(30)} 0 ${desktopVW(30)} ${desktopVW(62)};
    p {
      font-size: ${desktopVW(16)};
      font-family: 'messinaMono';
      color: ${colors.subtleGrey};
      line-height: ${desktopVW(25)};
    }
    a {
      font-size: ${desktopVW(16)};
      font-family: 'messinaMono';
      color: ${colors.lightGrey};
      line-height: ${desktopVW(25)};
    }
  }
`;

const LangContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  color: ${colors.lightGrey};
  font-family: 'messinaMono';

  ${desktopBreakpoint} {
    display: none;
  }
`;
