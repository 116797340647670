import Img from 'gatsby-image';
import Cookies from 'js-cookie';
import React, { useContext } from 'react';
import { animated, config, useSpring } from 'react-spring';
import styled from 'styled-components';
import { SettingsContext } from '../../contexts';
import { useGlobalComponentsQuery, useGlobalDictionaryQuery } from '../../hooks/index';
import { colors, desktopBreakpoint, desktopVW, mobileVW, zIndex } from '../../styles/index';
import Icons from '../../utils/Icons';
import SubscribeForm from './SubscribeForm';

interface Props {}

export default function NewsletterPopup(props: Props) {
  const {} = props;
  const dictionary = useGlobalDictionaryQuery();
  const {
    setrenderNewsletterPopup,
    purchaseAllowed,
  }: {
    setrenderNewsletterPopup: any;
    purchaseAllowed: any;
  } = useContext(SettingsContext);

  const handleSetCookie = () => {
    Cookies.set('_DBNL-closed', true, { expires: 365 });
  };

  const handleClose = () => {
    handleSetCookie();
    setrenderNewsletterPopup(false);
  };

  const popupSpring = useSpring({
    opacity: 1,
    from: { opacity: 0 },
    config: {
      ...config.stiff,
    },
  });

  const {
    newsletterTitleDomestic,
    newsletterTitleRow,
    newsletterParagraphDomestic,
    newsletterParagraphRow,
    newsletterPopupImage,
  }: {
    newsletterTitleDomestic: string;
    newsletterTitleRow: string;
    newsletterParagraphDomestic: string;
    newsletterParagraphRow: string;
    newsletterPopupImage: {
      fluid: string;
    };
  } = useGlobalComponentsQuery();
  return (
    <Wrapper style={{ ...popupSpring }}>
      <Background onClick={() => handleClose()} />
      <FormWrapper style={{ backgroundColor: dictionary.colorBackgroundNewsletterPopup }}>
        <StyledImg fluid={newsletterPopupImage.fluid} />
        <Inner>
          <Title style={{ color: dictionary.colorTitleDiscountNewsletterPopup }}>
            {purchaseAllowed && purchaseAllowed.allowed
              ? newsletterTitleDomestic
              : newsletterTitleRow}
          </Title>
          <Paragraph style={{ color: dictionary.colorDescriptionNewsletterPopup }}>
            {purchaseAllowed && purchaseAllowed.allowed
              ? newsletterParagraphDomestic
              : newsletterParagraphRow}
          </Paragraph>
          <SubscribeForm />
          <Closebutton onClick={() => handleClose()}>
            <Icons name="close" color={dictionary.colorIconCloseNewsletterPopup} />
          </Closebutton>
        </Inner>
      </FormWrapper>
    </Wrapper>
  );
}

const Wrapper = styled(animated.div)`
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${zIndex.satan};
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  ${desktopBreakpoint} {
    width: ${desktopVW(960)};
    height: ${desktopVW(560)};
    z-index: ${zIndex.satan + 1};
    flex-direction: row;
  }
`;

const StyledImg = styled(Img)`
  display: none;

  ${desktopBreakpoint} {
    display: block;
    width: 50%;
    height: 100%;
  }
`;

const Title = styled.div`
  font-family: 'messina';
  line-height: 90%;
  text-transform: uppercase;
  width: 90%;
  font-size: ${mobileVW(30)};

  ${desktopBreakpoint} {
    font-size: ${desktopVW(38)};
    width: 63%;
  }
`;

const Paragraph = styled.div`
  font-family: 'messinaMono', 'Courier New', Courier, monospace;
  letter-spacing: -0.05em;
  color: ${colors.subtleGrey};
  font-size: ${mobileVW(18)};
  width: 90%;
  padding: ${mobileVW(20)} 0 0 0;

  ${desktopBreakpoint} {
    font-size: ${desktopVW(16)};
    padding: ${desktopVW(20)} 0 0 0;
    width: 63%;
  }
`;

const Inner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: ${mobileVW(480)};
  position: relative;

  ${desktopBreakpoint} {
    width: 50%;
    height: 100%;
    justify-content: center;
  }
`;

const Closebutton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  padding: ${mobileVW(20)} ${mobileVW(20)};

  ${desktopBreakpoint} {
    padding: ${desktopVW(20)} 0;
    top: 0;
    right: ${desktopVW(20)};
  }
`;
