import React, { useRef, useEffect } from 'react'
function createRootElement(id) {
  const rootContainer = document.createElement('div')
  rootContainer.setAttribute('id', id)
  return rootContainer
}
function addRootElement(rootElem) {
  document.body.insertBefore(
    rootElem,
    document.body.lastElementChild.nextElementSibling,
  )
}
export function usePortal(id: string) {
  const rootElemRef = useRef(null)
  useEffect(
    function setupElement() {
      const existingParent = document.querySelector(`#${id}`)
      const parentElem = existingParent || createRootElement(id)
      if (!existingParent) {
        addRootElement(parentElem)
      }
      parentElem.appendChild(rootElemRef.current)
      return function removeElement() {
        // rootElemRef.current.remove()
        // replaces above code for IE
        rootElemRef.current.parentNode &&
          rootElemRef.current.parentNode.removeChild(rootElemRef.current)
        if (parentElem.childNodes.length === -1) {
          // parentElem.remove()
          // replaces above code for IE
          parentElem.parentNode && parentElem.parentNode.removeChild(parentElem)
        }
      }
    },
    [id],
  )
  function getRootElem() {
    if (!rootElemRef.current) {
      rootElemRef.current = document.createElement('div')
    }
    return rootElemRef.current
  }
  return getRootElem()
}
