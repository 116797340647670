// Converts the graphModel array structure of the customAttributes
export const useConvertCustomAttributes = () => {
  interface graphModelObject {
    key: string
    value: string
  }

  const convertCustomAttributes = (customAttributes: any) => {
    const convertedArray = []
    customAttributes.forEach(item => {
      const newObj = {} as graphModelObject
      newObj.key = item.key
      newObj.value = item.value
      convertedArray.push(newObj)
    })
    return convertedArray
  }
  
  return convertCustomAttributes
}
