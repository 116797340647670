import { useRemoveItemsFromCart } from './useRemoveItemsFromCart'

export function useRemoveItemFromCart() {
  const removeItemsFromCart = useRemoveItemsFromCart()

  async function removeItemFromCart(lineItemId: number | string) {
    if (lineItemId === '' || lineItemId == null) {
      throw new Error('lineItemId must not be blank or null')
    }

    return removeItemsFromCart([String(lineItemId)])
  }

  return removeItemFromCart
}
