/* eslint-disable */
import React, { useContext, useEffect, useState } from 'react';
import { animated, useSpring } from 'react-spring';
import styled from 'styled-components';
import { SettingsContext } from '../../../contexts';
import { useGlobalLayoutQuery } from '../../../hooks/index';
import { useLocale } from '../../../hooks/layout/useLocale';
import { colors, desktopBreakpoint, desktopVW, zIndex } from '../../../styles/index';
import DelayedLink from '../../../utils/DelayedLink';

interface ProductInterface {
  productCategory: {
    category: string;
    subCategory: string;
  };
  shopifyData: {
    variants: [
      {
        compare_at_price: null | string;
        price: string;
      }
    ];
  };
  slug: string;
  thumbnailImages: [
    {
      fluid: any;
    }
  ];
  title: string;
  titleToShow: string;
}

interface Props {
  index: number;
  title: string;
  color: string;
  visible: boolean;
  target: { slug: string };
  handleSubMenuClick: (data: string) => void;
  targetData: string;
  products: [ProductInterface];
  typeName: string;
  setSecondMenuProducts: () => void;
}

export default function MenuChildDesktop(props: Props) {
  const layout = useGlobalLayoutQuery();
  const {
    index,
    title,
    visible,
    target,
    color,
    handleSubMenuClick,
    targetData,
    products,
    typeName,
    setSecondMenuProducts,
  } = props;

  const {
    isMenuOpen,
    secondMenuVisible,
    setSecondMenuVisible,
    secondMenuContent,
    setSecondMenuContent,
  }: {
    isMenuOpen: boolean;
    setMenuOpen: any;
    secondMenuVisible: any;
    setSecondMenuVisible: any;
    secondMenuContent: any;
    setSecondMenuContent: any;
  } = useContext(SettingsContext);

  const [isBorderOn, setIsBorderOn] = useState<boolean>(false);
  const locale = useLocale();

  const delay = (index): number => {
    if (!visible) return 0;
    if (visible) return index * 50;
  };

  const delayedCallback = (): void => {
    handleSubMenuClick(targetData);
  };

  const handleMouseEnter = (title: string, link: string, typeName: string): void => {
    setIsBorderOn(true);
    if (typeName === 'ContentfulComponentMenu') {
      if (secondMenuVisible) {
        setSecondMenuContent(title);
      } else {
        if (!isMenuOpen) return;
        setSecondMenuVisible(true);
        setSecondMenuContent(title);
      }
    } else {
      setSecondMenuProducts({});
      setSecondMenuVisible(false);
      setSecondMenuContent('');
    }
  };

  const handleMouseLeave = (): void => {
    if (secondMenuContent === '') {
      setIsBorderOn(false);
    }
  };

  useEffect(() => {
    if (secondMenuContent === title) {
      setIsBorderOn(true);
    } else {
      setIsBorderOn(false);
    }
  }, [secondMenuContent]);

  useEffect(() => {
    if (products && products[0]) {
      // products are categorised by category in node, so no prob. in grabbing just index 0
      if (title === secondMenuContent) {
        const callBack = setTimeout(() => {
          setSecondMenuProducts({ products, targetData });
        }, 300);
        return () => clearTimeout(callBack);
      }
    }
  }, [secondMenuContent]);

  const leftMenuChildSpring = useSpring({
    to: { opacity: visible ? 1 : 0 },
    config: {
      tension: 210,
      friction: 20,
      clamp: true,
    },
    delay: delay(index),
  });

  const borderSpring = useSpring({
    transform: isBorderOn ? 'translateX(0%)' : 'translateX(-105%)',
    config: {
      tension: 220,
      friction: 25,
      clamp: true,
    },
  });

  return (
    <Wrapper>
      <Text style={{ ...leftMenuChildSpring }}>
        <BorderOnHoverWrapper
          onMouseEnter={() => {
            handleMouseEnter(title, targetData ? `${target.slug}/` : target.slug, typeName);
          }}
          onMouseLeave={() => handleMouseLeave()}
          isNl={locale === 'nl'}
        >
          <DelayedLink
            location={location}
            callback={delayedCallback}
            delay={1000}
            to={
              targetData && targetData !== '%'
                ? `/${locale}/${encodeURIComponent(
                    targetData
                      .replace('%', '')
                      .replace('&', '-and-')
                      .replace(/ /g, '')
                      .toLowerCase()
                  )}`
                : `/${locale}/${target.slug}`
            }
          >
            {color != 'default' ? (
              <>
                <span style={{ color }}> {title}</span>
                <Border style={{ ...borderSpring, backgroundColor: color }} />
              </>
            ) : (
              <>
                <span style={{ color: layout.colorMenuInSidebar }}> {title}</span>
                <Border
                  style={{ ...borderSpring, backgroundColor: layout.colorUnderlineMenuInSidebar }}
                />
              </>
            )}
          </DelayedLink>
          {/* <TextSpan>{totalProd}</TextSpan> */}
        </BorderOnHoverWrapper>
      </Text>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: ${desktopVW(44)};
  text-align: left;
  //padding-left: ${desktopVW(60)};
  overflow: hidden;
  will-change: transform;

  ${desktopBreakpoint}{
  
  }
`;

const CenterMenuChildWrapper = styled(animated.div)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: ${desktopVW(44)};
  text-align: left;
  //padding-left: ${desktopVW(60)};
  overflow: hidden;

  ${desktopBreakpoint}{
  
  }
`;

const Text = styled(animated.div)`
  font-size: ${desktopVW(30)};
  font-family: 'messinaMono';
  //transform: translateY(-180%);
  opacity: 0;
  cursor: pointer;
  will-change: opacity;
  position: relative;
  z-index: ${zIndex.header};
`;

const TextSpan = styled.span`
  display: inline-block;
  font-size: ${desktopVW(15)};
  font-family: 'messinaMono';
  transform: ${`translate(${desktopVW(5)}, -50%)`};
`;

const Border = styled(animated.div)`
  position: absolute;
  bottom: ${desktopVW(0)};
  left: 0;
  height: ${desktopVW(2)};
  background-color: ${colors.white};
  width: 100%;

  ${desktopBreakpoint} {
  }
`;

const BorderOnHoverWrapper = styled.div`
  position: relative;

  ${desktopBreakpoint} {
    ${({ isNl }) => (isNl ? `font-size: ${desktopVW(24)};` : '')}
  }
`;
